import { useEditEntityDrawer, UseEditEntityDrawerReturnType } from '../UseEditEntityDrawer';

export type DeleteDialogState = {
  isOpen: boolean;
  order?: any;
  onSubmitCallback?: () => void;
  event?: any;
  unavailability?: any;
  note?: any;
  shift?: any;
};

export const useDeleteDialog = (): UseEditEntityDrawerReturnType<DeleteDialogState> => {
  return useEditEntityDrawer<DeleteDialogState>({ isOpen: false });
};
