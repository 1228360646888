import './DetailsStep.scss';
import React from 'react';
import { useOrderPage } from '../orderPageContext/useOrderPage';
import UiObjectsList from '../ui-objects/UiObjectsList';
import EscortsWarning from '../FreeEscortsWarning';

const DetailsStep = (props) => {
  const { errors } = props;
  const {
    orderPageState,
    setOrderPageState,
    setOrderDetailValue,
    setCustomOrderDetailValue,
    setOrderCustomDetails,
    setMultipleTicketsDetails,
  } = useOrderPage();

  return (
    <div>
      <UiObjectsList
        isLoadingDates={orderPageState.isLoadingDates}
        type="order"
        orderState={orderPageState}
        setOrderState={setOrderPageState}
        setOrderDetailValue={setOrderDetailValue}
        setCustomOrderDetailValue={setCustomOrderDetailValue}
        setOrderCustomDetails={setOrderCustomDetails}
        errors={errors}
        setMultipleTicketsDetails={setMultipleTicketsDetails}
      />
      <EscortsWarning orderPage={orderPageState.orderPage} />
    </div>
  );
};

export default DetailsStep;
