import { useEditEntityDrawer, UseEditEntityDrawerReturnType } from '../UseEditEntityDrawer';

export type EditOrderDrawerState = {
  order?: any;
  orderPageId?: string;
  parentId?: string;
  parent?: any;
  voucherToRedeem?: any;
  createVoucher?: any;
  voucherToEdit?: any;
  cloneOrder?: any;
  isOpen: boolean;
};

export const useEditOrderDrawer = (): UseEditEntityDrawerReturnType<EditOrderDrawerState> => {
  return useEditEntityDrawer<EditOrderDrawerState>({ isOpen: false });
};
