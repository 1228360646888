import { LoadingButton } from '@mui/lab';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { compact } from 'lodash';
import { useAuthInfo } from '@propelauth/react';
import { Alert, Grid, Snackbar } from '@mui/material';
import { UseAuthPermissions } from '../../../session/UseAuthPermissions';
import { DraggableItem } from './DraggableItems';
import { AccountService, EmailTemplateType, Language } from '../../../api';

import { useDragDropContext } from './DragDropProvider';

type UpdateTemplateSubmitButtonProps = {
  selectedLanguage: Language;
  templateType: EmailTemplateType;
  orderPageIdOverride?: string;
};
export const TestEmailButton = ({
  selectedLanguage,
  templateType,
  orderPageIdOverride,
}: UpdateTemplateSubmitButtonProps) => {
  const { user } = useAuthInfo();

  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSuccessSnackOpen, setIsSuccessSnackOpen] = useState(false);
  const [isFailSnackOpen, setIsFailSnackOpen] = useState(false);

  const { readOnly } = UseAuthPermissions();
  const { items } = useDragDropContext();
  const isButtonDisabled = readOnly || isSubmitting;

  const handleSubmit = async (values: DraggableItem[]) => {
    try {
      const blocks = compact(values.filter((item) => !item.isStatic).map((item) => item.toEmailBlockData?.()));
      await AccountService.sendTestEmail(orderPageIdOverride, templateType, selectedLanguage, user?.email, blocks);
      setIsSuccessSnackOpen(true);
    } catch (e) {
      setIsFailSnackOpen(true);
      console.error(e);
    }
  };
  return (
    <Grid>
      <LoadingButton
        fullWidth
        onClick={async () => {
          setIsSubmitting(true);
          await handleSubmit(items);
          setIsSubmitting(false);
        }}
        size="large"
        loading={isSubmitting}
        type="submit"
        variant="outlined"
        color="primary"
        disabled={isButtonDisabled}>
        {t('emailTemplates.sendTest')}
      </LoadingButton>
      <Snackbar
        open={isSuccessSnackOpen}
        onClose={() => setIsSuccessSnackOpen(false)}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert sx={{ width: '100%' }} onClose={() => setIsSuccessSnackOpen(false)} severity="success" variant="filled">
          {t('emailTemplates.emailSent', {
            email: user!.email,
          })}
        </Alert>
      </Snackbar>
      <Snackbar
        open={isFailSnackOpen}
        onClose={() => setIsFailSnackOpen(false)}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert sx={{ width: '100%' }} onClose={() => setIsFailSnackOpen(false)} severity="error" variant="filled">
          {t('emailTemplates.emailFailed', {
            email: user!.email,
          })}
        </Alert>
      </Snackbar>
    </Grid>
  );
};
