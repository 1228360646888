import './PaymentStep.scss';
import React, { useEffect, useRef, useState } from 'react';
import Color from 'color';
import moment from 'moment';
import 'moment-timezone';
import { useTranslation } from 'react-i18next';
import {
  Alert,
  Box,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import { includes, some } from 'lodash';
import { CheckCircleOutline } from '@mui/icons-material';
import { useOrderPage } from '../orderPageContext/useOrderPage';
import TimeUtils from '../../common/TimeUtils';
import { OrderPageTextField } from '../themedComponents/OrderPageTextField';
import { OrderPageButton } from '../themedComponents/OrderPageButton';
import { OrderPageTypography } from '../themedComponents/OrderPageTypography';
import { priceFormatter } from '../utils';
import PaymentIframe from './PaymentIframe';
import {
  AccountData,
  ApplyCodeExceptionResponse,
  CodeExceptionStatus,
  CodesService,
  GetPaymentFormRequestData,
  PaymentFormType,
  PaymentsService,
  Tip,
  TipType,
} from '../../api';
import { TipComponent } from './payments/TipComponent';
import RotatingLoader from '../../common/ui/RotatingLoader';
import { getDateFormat, getTimeFormat } from '../../common/getDateAndTimeFormat';
import { OrderPageIntent } from '../OrderPageIntent';

const PaymentStep = ({
  orderPageIntent,
  sessionTtlMilliseconds,
}: {
  orderPageIntent: OrderPageIntent;
  sessionTtlMilliseconds: number | null;
}) => {
  const theme = useTheme();

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const { orderPageState, setTip } = useOrderPage();
  const { t } = useTranslation();
  const [isLoadingApplyCode, setIsLoadingApplyCode] = useState(false);
  const [shouldShowVoucherSuccess, setShouldShowCodeApplySuccess] = useState(false);
  const [voucherOrDiscount, setVoucherOrDiscount] = useState('');
  const [voucherError, setCodeApplyError] = useState<string | null>(null);
  const [loadingPaymentLink, setLoadingPaymentLink] = useState(false);
  const navigate = useNavigate();
  const shouldShowDuration = orderPageState.orderPage.show_duration;
  const [paymentUrl, setPaymentUrl] = useState(orderPageState?.paymentUrl);
  const stripeAccountId = orderPageState.account.stripe_connect?.stripe_account_id;
  const getPaymentForm = async () => {
    setLoadingPaymentLink(true);
    // component only used in the us for refetching with tip
    const payload: GetPaymentFormRequestData = {
      language: 'en',
      account_id: orderPageState.account.id,
      experience_id: orderPageState.experience.id,
      order_page_id: orderPageState.orderPage.id,
      entity_id: orderPageState.order_id,
      payment_form_type:
        orderPageIntent === OrderPageIntent.GIFT_CARD ? PaymentFormType.VOUCHER : PaymentFormType.ORDER,
    };
    if (orderPageState?.tip?.value && orderPageState?.tip?.value > 0) {
      payload.tip = orderPageState?.tip;
    }
    // component only used in the us
    const url = await PaymentsService.getPaymentForm(payload);
    setPaymentUrl(url);
    setLoadingPaymentLink(false);
  };
  useEffect(() => {
    if (!orderPageState?.tip) {
      return;
    }
    void getPaymentForm();
  }, [orderPageState?.tip]);

  const [discountedPrice, setDiscountedPrice] = useState(orderPageState?.details.price);

  const [dynamicSessionTtlMilliseconds, setDynamicSessionTtlMilliseconds] = useState<number | null>(null);
  const dynamicSessionTtlMillisecondsIntervalRef = useRef<number>();
  useEffect(() => {
    if (sessionTtlMilliseconds === null) {
      setDynamicSessionTtlMilliseconds(null);
      return;
    }

    setDynamicSessionTtlMilliseconds(sessionTtlMilliseconds);
    if (sessionTtlMilliseconds <= 0) {
      return;
    }

    dynamicSessionTtlMillisecondsIntervalRef.current = setInterval(() => {
      setDynamicSessionTtlMilliseconds((currentValue) => {
        if (currentValue === null) {
          clearInterval(dynamicSessionTtlMillisecondsIntervalRef.current);
          return null;
        }

        if (currentValue <= 1000) {
          clearInterval(dynamicSessionTtlMillisecondsIntervalRef.current);
          return 0;
        }

        return currentValue - 1000;
      });
    }, 1000) as unknown as number;

    // eslint-disable-next-line consistent-return
    return () => {
      clearInterval(dynamicSessionTtlMillisecondsIntervalRef.current);
    };
  }, [sessionTtlMilliseconds]);

  if (orderPageState.details.price === 0) {
    window.parent.location.href = '/order/completed';
    return <Grid />;
  }
  const submitCode = async () => {
    setShouldShowCodeApplySuccess(false);
    setIsLoadingApplyCode(true);
    setCodeApplyError(null);

    try {
      const resp = await CodesService.applyCode({
        entity_id: orderPageState.order_id,
        code: voucherOrDiscount,
      });
      if (resp.payment_link) {
        setPaymentUrl(resp.payment_link);
      }
      if (resp.new_price === 0) {
        navigate('/order/completed');
      }
      if (resp.new_price) {
        setDiscountedPrice(resp.new_price);
      }
      setShouldShowCodeApplySuccess(true);
      setIsLoadingApplyCode(false);
    } catch (e: any) {
      if (e.status === 400) {
        const error = e.body as ApplyCodeExceptionResponse;
        let errorstr = 'err';
        switch (error.code) {
          case CodeExceptionStatus.VOUCHER_STATUS_ERROR:
            errorstr = t('discounts.errors.notActive');
            break;
          case CodeExceptionStatus.ENTITY_NOT_FOUND:
            errorstr = t('vouchererrors.entitynotfound');
            break;
          case CodeExceptionStatus.CODE_NOT_FOUND:
            errorstr = t('vouchererrors.notfound');
            break;
          case CodeExceptionStatus.ORDER_NOT_MATCH_VOUCHER:
            errorstr = t('vouchererrors.missmatch');
            break;
          case CodeExceptionStatus.VOUCHER_TOO_OLD:
            errorstr = t('vouchers.expired');
            break;
          case CodeExceptionStatus.VOUCHER_USED:
            errorstr = t('vouchererrors.used');
            break;
          case CodeExceptionStatus.DISCOUNT_NOT_ACTIVE:
            errorstr = t('vouchererrors.discountNotActive');
            break;
          case CodeExceptionStatus.RETURNING_CUSTOMER_TOO_LATE:
            errorstr = t('vouchererrors.returningCustomerTooLate');
            break;
          case CodeExceptionStatus.DISCOUNT_USAGE_LIMIT_EXCEEDED:
            errorstr = t('vouchererrors.usageLimitExceeded');
            break;
          case CodeExceptionStatus.DISCOUNT_ORDER_PAGE_NOT_VALID:
            errorstr = t('vouchererrors.orderPageNotValid');
            break;
          case CodeExceptionStatus.ORDER_DATE_BEFORE_REDEMPTION_PERIOD:
            errorstr = t('vouchererrors.orderDateBeforeRedemptionPeriod');
            break;
          case CodeExceptionStatus.ORDER_DATE_AFTER_REDEMPTION_PERIOD:
            errorstr = t('vouchererrors.orderDateAfterRedemptionPeriod');
            break;
          case CodeExceptionStatus.DISCOUNT_NOT_VALID_FOR_ORDERS:
            errorstr = t('vouchererrors.discountNotValidForOrders');
            break;
          case CodeExceptionStatus.DISCOUNT_NOT_VALID_FOR_VOUCHERS:
            errorstr = t('vouchererrors.discountNotValidForVouchers');
            break;
          case CodeExceptionStatus.DISCOUNT_ALREADY_USED:
            errorstr = t('vouchererrors.onlyOncePerEmail');
            break;
          default:
            setIsLoadingApplyCode(false);
            throw e;
        }
        setIsLoadingApplyCode(false);
        setCodeApplyError(errorstr);
      } else {
        setIsLoadingApplyCode(false);
        throw e;
      }
    }
  };

  const handleVoucherCodeChange = (event: any) => {
    setVoucherOrDiscount(event.target.value);
  };
  const shouldShowSandbox =
    paymentUrl && some(['sandbox', 'paymentsdev', 'sc_test', 'elibis'], (substring) => includes(paymentUrl, substring));
  const account = orderPageState.account as AccountData;
  const timeFormat = getTimeFormat(account);
  let stripe_integration_publishable_key;

  // new stripe connection
  if (account.stripe_connect) {
    stripe_integration_publishable_key = account.stripe_connect.is_stripe_test_account
      ? import.meta.env.VITE_REACT_APP_TEST_STRIPE_PUBLISHABLE_KEY
      : import.meta.env.VITE_REACT_APP_STRIPE_PUBLISHABLE_KEY;
  }

  function calculateTip(tip?: Tip, price?: number) {
    if (!tip || !price) {
      return 0;
    }
    if (tip.type === TipType.FIXED) {
      return tip.value;
    }
    return (price * tip.value) / 100;
  }

  const paymentTimerDuration =
    dynamicSessionTtlMilliseconds !== null ? moment.duration(dynamicSessionTtlMilliseconds, 'milliseconds') : null;
  return (
    <Box className="payment-step">
      <Grid container alignItems="flex-start" className="payment-step" mb={2}>
        <Grid item container gap={1} mt={isSmallScreen ? undefined : 2}>
          {orderPageState.returningCustomer && (
            <Grid container my={2}>
              <Typography variant="h5" fontWeight={800}>
                {t('order_page.welcomeBack', { name: orderPageState.personalDetails.firstname })}
              </Typography>
            </Grid>
          )}
          {paymentTimerDuration && (
            <Grid item container mb={1}>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  textAlign: 'center',
                  borderRadius: 1.25,
                  py: 0.25,
                  px: 2,
                  flex: 1,
                  bgcolor: Color(theme.customTheme.palette.text.primary).alpha(0.2).string(),
                }}>
                <Typography variant="body1">
                  {t('paymentTimer.prefix')}{' '}
                  <strong>
                    {t('paymentTimer.timeBold', {
                      timeMinutes: `${paymentTimerDuration.minutes().toString().padStart(2, '0')}:${paymentTimerDuration
                        .seconds()
                        .toString()
                        .padStart(2, '0')}`,
                    })}{' '}
                    {t('duration.min')}{' '}
                  </strong>{' '}
                  {t('paymentTimer.suffix')}
                </Typography>
              </Box>
            </Grid>
          )}
          <Grid item mb={2}>
            <Typography variant="h5">{t('orderdetails')}</Typography>
          </Grid>
          {orderPageIntent === OrderPageIntent.BUY_NOW && (
            <>
              <Grid item container gap={2}>
                {t('order_page.date')}
                <strong>
                  {moment(orderPageState.dateAndTimeDetails.date).format(getDateFormat(account).longDateFormat)}
                </strong>
              </Grid>

              <Grid item container gap={2}>
                {t('order_page.time')}
                <strong>{moment.unix(orderPageState.dateAndTimeDetails.time).tz('utc').format(timeFormat)}</strong>
              </Grid>
            </>
          )}

          <Grid item container gap={2}>
            {t('order_page.persons')}
            <strong>{orderPageState.details.persons}</strong>
          </Grid>
          {shouldShowDuration && (
            <Grid item container gap={2}>
              {t('order_page.duration')}
              <strong> {TimeUtils.formatDuration(orderPageState.details.duration, t)}</strong>
            </Grid>
          )}
        </Grid>

        {!orderPageState.account.has_payment_integration && (
          <Grid
            item
            xs={12}
            sm={7}
            justifyContent="center"
            className="price-container"
            sx={{ justifyContent: { xs: 'flex-start' } }}>
            <TableContainer
              sx={{
                border: '1px solid rgba(224, 224, 224, 1)',
                background: 'white',
                borderBottom: '0',
              }}>
              <Table>
                <TableBody>
                  <TableRow sx={{ borderBottom: '0' }}>
                    <TableCell>
                      <strong>{t('order_page.total')}:</strong>
                    </TableCell>
                    <TableCell sx={{ minWidth: '50px' }}>
                      <strong>{priceFormatter(orderPageState.details.price, orderPageState.account)}</strong>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        )}

        {!orderPageState.account.has_payment_integration && (
          <>
            <Grid item xs={12} className="details-container" sx={{ borderTop: '1px solid #ddd' }}>
              <p>
                {t('order_page.firstname')}
                <strong>{orderPageState.personalDetails.firstname}</strong>
              </p>
              <p>
                {t('order_page.lastname')}
                <strong>{orderPageState.personalDetails.lastname}</strong>
              </p>
              <p>
                {t('order_page.email')} <strong>{orderPageState.personalDetails.email}</strong>
              </p>
              <p>
                {t('order_page.phone')} <strong>{orderPageState.personalDetails.phone}</strong>
              </p>
            </Grid>

            <Alert severity="info" className="payment-footer" sx={{ marginBottom: '32px' }}>
              <div className="payment-footer-text">{t('order_page.payment_in_location')}</div>
            </Alert>
          </>
        )}
      </Grid>
      <Divider style={{ backgroundColor: theme.customTheme.palette.border }} />
      <Grid item container my={2} gap={2}>
        {t('order_page.price')}
        <strong> {priceFormatter(orderPageState?.details.price, orderPageState.account)}</strong>
      </Grid>
      {discountedPrice !== orderPageState?.details.price && (
        <Grid item container my={2} gap={2}>
          <Typography fontWeight={700} variant="h6" color={theme.customTheme.palette.primary}>
            {t('order_page.priceAfterDiscount')}
          </Typography>
          <Typography variant="h6" color={theme.customTheme.palette.primary} fontWeight={700}>
            {priceFormatter(discountedPrice, orderPageState.account)}
          </Typography>
        </Grid>
      )}
      {orderPageState?.orderPage?.extra_price_component?.tip && (
        <Grid container flexDirection="column" mb={2}>
          {orderPageState?.tip?.value && orderPageState?.tip?.value > 0 && (
            <Grid container item gap={2} mb={2}>
              <OrderPageTypography>Gratuity:</OrderPageTypography>
              <OrderPageTypography bold>
                {priceFormatter(calculateTip(orderPageState?.tip, orderPageState?.details.price), account)}
              </OrderPageTypography>
            </Grid>
          )}
          <TipComponent tipInitial={orderPageState.tip?.value} setTip={setTip} />
        </Grid>
      )}
      <Divider style={{ backgroundColor: theme.customTheme.palette.border }} />
      {includes([OrderPageIntent.BUY_NOW, OrderPageIntent.GIFT_CARD], orderPageIntent) && (
        <Grid container flexDirection="column" my={2}>
          <Grid item mb={2}>
            <Typography variant="h5">{t('paymentwithvoucher')}</Typography>
          </Grid>
          <Grid item container gap={4} alignItems="center">
            <Grid item xs={isSmallScreen ? 6 : 8}>
              <OrderPageTextField
                size="small"
                fullWidth
                placeholder={t('vouchercode')}
                onChange={handleVoucherCodeChange}
                value={voucherOrDiscount}
                error={!!voucherError}
                helperText={voucherError}
              />
            </Grid>
            <OrderPageButton loadingButton variant="outlined" loading={isLoadingApplyCode} onClick={submitCode}>
              <OrderPageTypography>{t('apply')}</OrderPageTypography>
            </OrderPageButton>
            <Grid item hidden={!shouldShowVoucherSuccess}>
              <CheckCircleOutline style={{ color: '#4bbd00' }} />
            </Grid>
          </Grid>
        </Grid>
      )}

      {orderPageState.account.has_payment_integration && (
        <Grid container flexDirection="column">
          <Typography variant="h5">{t('paymentwithcard')}</Typography>
          {shouldShowSandbox && <Typography color="error">SANDBOX</Typography>}
          {loadingPaymentLink || !paymentUrl ? (
            <Grid container height="500px">
              <RotatingLoader />
            </Grid>
          ) : (
            <PaymentIframe
              paymentUrl={paymentUrl}
              stripe_integration_publishable_key={stripe_integration_publishable_key}
              stripeAccountId={stripeAccountId}
            />
          )}
        </Grid>
      )}
    </Box>
  );
};

export default PaymentStep;
