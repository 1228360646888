import { Grid } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import moment from 'moment/moment';
import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ToggleDays from '../../common/ui/ToggleDays';
import { DATE_FORMAT } from '../CalendarWrapper';
import { getDateFormat } from '../../common/getDateAndTimeFormat';
import { useAuth } from '../../session/InternalAuthProvider';
import { AccountData } from '../../api';

interface UseRepeatingDaysResult {
  RepeatingDaysComponent: (props: RepeatingDaysComponentProps) => JSX.Element;
  days: number[];
  endDate: moment.Moment;
  setEndDate: (newDate: moment.Moment) => void;
}

type RepeatingDaysComponentProps = {
  setFormikEndDate?: (newDate: string) => void;
  setFormikDays?: (days: number[]) => void;
};
type UseRepeatingDaysProps = {
  minDate: moment.Moment;
  initialDays?: number[];
  initialEndDate?: string;
};
export const UseRepeatingDays = ({
  minDate,
  initialDays,
  initialEndDate,
}: UseRepeatingDaysProps): UseRepeatingDaysResult => {
  const { t } = useTranslation();
  const maxDate = moment().add(180, 'days');
  const { authState } = useAuth();
  const account = authState.account as unknown as AccountData;
  const { longDateFormat } = getDateFormat(account);

  const [days, setDays] = useState(initialDays || [0, 1, 2, 3, 4, 5, 6]);
  const [endDateOpen, setEndDateOpen] = useState(false);
  const [endDate, setEndDate] = useState(moment(initialEndDate) || moment().startOf('day'));
  const RepeatingDaysComponent = ({ setFormikEndDate, setFormikDays }: RepeatingDaysComponentProps) => (
    <Grid p={3} pt={0}>
      <Grid container flexDirection="row" alignItems="center" spacing={1}>
        <Grid item>{t('calendar.unavailability_dialog.in_days')}</Grid>
        <Grid item>
          <ToggleDays
            days={days}
            setDays={(newDays: number[]) => {
              setDays(newDays);
              if (setFormikDays) {
                setFormikDays(newDays);
              }
            }}
          />
        </Grid>
      </Grid>
      <Grid container alignItems="center" gap={2} mt={1}>
        <Grid item>{t('calendar.unavailability_dialog.end_repeating')}</Grid>
        <Grid item>
          <DatePicker
            open={endDateOpen}
            onOpen={() => setEndDateOpen(true)}
            onClose={() => setEndDateOpen(false)}
            format={longDateFormat}
            value={endDate}
            onChange={(newValue) => {
              const newEndDate = newValue || moment().startOf('day');
              setEndDate(newEndDate);
              if (setFormikEndDate) {
                setFormikEndDate(newEndDate.format(DATE_FORMAT));
              }
            }}
            disablePast
            minDate={minDate}
            maxDate={maxDate}
          />
        </Grid>
      </Grid>
    </Grid>
  );
  return { RepeatingDaysComponent, days, endDate, setEndDate };
};
