import React, { createContext, useContext, useState } from 'react';

import { useAuth } from '../../../session/InternalAuthProvider';
import OrderUtils, {
  calcPrice,
  MULTIPLE_TICKETS_DETAILS_FIELD_NAME,
  MULTIPLE_TICKETS_MIN_PARTICIPANTS_FIELD_NAME,
} from '../../../order_page/OrderUtils';
import { PluginType } from '../../../order_page/PluginUtils';

import { DATE_FORMAT } from '../../CalendarWrapper';
import { OrderStatus } from '../../../api';
import { getOrderPages } from '../../../common/AccountUtils';
import { orderPageInitialState } from '../../../order_page/orderPageContext/OrderPageState';
import { pluginEnabled } from '../../../order_page/orderPageContext/OrderPageProvider';

const initialState = { ...orderPageInitialState, ...OrderUtils.initialEditOrderState };

const EditOrderContext = createContext(initialState);

export const useEditOrder = () => useContext(EditOrderContext);

export const EditOrderProvider = (props) => {
  const { authState } = useAuth();
  const { account } = authState;
  const { order, voucherToRedeem } = props;
  let { orderPageId } = props;
  const experience = account.experiences[0];
  orderPageId = orderPageId || (order ? order.order_page_id : getOrderPages(account, true)[0].id);
  const shouldKeepPrice = order || voucherToRedeem;
  const keptPrice = order?.price || voucherToRedeem?.price || 0;

  const orderPage = getOrderPages(account, true).find((o) => o.id === orderPageId);
  initialState.account = account;
  initialState.experience = experience;
  initialState.orderPage = orderPage;

  if (order) {
    initialState.details.persons = order.persons;
    initialState.details.duration = order.duration;
    initialState.details.price = order.price;
    initialState.personalDetails.firstname = order.firstname;
    initialState.personalDetails.lastname = order.lastname;
    initialState.personalDetails.email = order.email;
    initialState.personalDetails.phone = order.phone;
    initialState.details.custom_details = order.custom_details || OrderUtils.getDefaultCustomDetails(orderPage);
    // we want to allow override price when its manual rder
    initialState.shouldAllowPriceOverride = order.status === OrderStatus.CONFIRMED;
    initialState.extraPaymentsLines = order.extra_payment_lines;
    initialState.calculatedPrice = calcPrice(
      orderPage.price,
      initialState.details,
      {
        date: order.date,
        time: order.time,
      },
      orderPage.plugins,
    );
    initialState.order_id = order.id;
    initialState.discountId = order.discount_id;
    initialState.returningCustomerFromOrderId = order.returning_customer_from_order_id;
  } else if (voucherToRedeem) {
    initialState.details.persons = voucherToRedeem.persons;
    initialState.details.duration = voucherToRedeem.duration;
    initialState.details.price = voucherToRedeem.price;
    initialState.personalDetails.firstname = voucherToRedeem.firstname;
    initialState.personalDetails.lastname = voucherToRedeem.lastname;
    initialState.personalDetails.email = voucherToRedeem.email;
    initialState.personalDetails.phone = voucherToRedeem.phone;
    initialState.details.custom_details =
      voucherToRedeem.custom_details || OrderUtils.getDefaultCustomDetails(orderPage);
    initialState.shouldAllowPriceOverride = false;
    initialState.extraPaymentsLines = [];
  } else {
    // Orders manually added can have the price changed when adding them.
    initialState.shouldAllowPriceOverride = true;
    initialState.details.persons = orderPage.persons;
    initialState.details.duration = orderPage.duration;
    initialState.details.custom_details = OrderUtils.getDefaultCustomDetails(orderPage);
    const calculatedPrice = calcPrice(
      orderPage.price,
      initialState.details,
      initialState.dateAndTimeDetails,
      orderPage.plugins,
    );
    initialState.details.price = calculatedPrice;
    initialState.calculatedPrice = calculatedPrice;
    initialState.order_id = '';
  }

  const [editOrderState, setEditOrderState] = useState(initialState);

  const setOrderDetailValue = (dataType, value) => {
    const tempDetails = {
      ...editOrderState.details,
      [dataType]: value,
    };
    let shouldUseweekendPlugin = false;
    if (pluginEnabled(editOrderState.details?.custom_details, PluginType.WEEKEND_AND_ISRAELI_HOLIDAYS)) {
      shouldUseweekendPlugin = true;
    }
    const calculatedPrice = calcPrice(
      editOrderState.orderPage.price,
      tempDetails,
      editOrderState.dateAndTimeDetails,
      editOrderState.orderPage.plugins,
      shouldUseweekendPlugin,
    );

    setEditOrderState({
      ...editOrderState,
      calculatedPrice,
      details: {
        ...tempDetails,
        price: shouldKeepPrice ? keptPrice : calculatedPrice,
      },
    });
  };

  const setCustomOrderDetailValue = (name, value) => {
    const tempDetails = {
      ...editOrderState.details,
      custom_details: {
        ...editOrderState.details.custom_details,
        [name]: value,
      },
    };

    const calculatedPrice = calcPrice(
      editOrderState.orderPage.price,
      tempDetails,
      editOrderState.dateAndTimeDetails,
      editOrderState.orderPage.plugins,
    );

    setEditOrderState({
      ...editOrderState,
      calculatedPrice,
      details: {
        ...tempDetails,
        price: shouldKeepPrice ? keptPrice : calculatedPrice,
      },
    });
  };
  const calcPriceWithCustomDetails = (tempDetails, customDetails, orderPage) => {
    let shouldUseweekendPlugin = false;
    if (pluginEnabled(customDetails, PluginType.WEEKEND_AND_ISRAELI_HOLIDAYS)) {
      shouldUseweekendPlugin = true;
    }
    return calcPrice(
      orderPage.price,
      tempDetails,
      editOrderState.dateAndTimeDetails,
      orderPage.plugins,
      shouldUseweekendPlugin,
    );
  };
  const setOrderCustomDetails = (customDetails) => {
    const tempDetails = {
      ...editOrderState.details,
      custom_details: {
        ...editOrderState.details.custom_details,
        ...customDetails,
      },
    };
    const calculatedPrice = calcPriceWithCustomDetails(tempDetails, customDetails, editOrderState.orderPage);
    setEditOrderState({
      ...editOrderState,
      calculatedPrice,
      details: {
        ...tempDetails,
        price: shouldKeepPrice ? keptPrice : calculatedPrice,
      },
    });
  };
  const setMultipleTicketsDetails = (multipleTicketsDetails) => {
    const totalPersons = Object.values(multipleTicketsDetails).reduce((sum, val) => sum + val, 0);

    const tempDetails = {
      ...editOrderState.details,
      persons: totalPersons,
      custom_details: {
        ...editOrderState.details.custom_details,
        [MULTIPLE_TICKETS_DETAILS_FIELD_NAME]: {
          ...editOrderState.details.custom_details.multiple_tickets_details,
          ...multipleTicketsDetails,
        },
        [MULTIPLE_TICKETS_MIN_PARTICIPANTS_FIELD_NAME]: editOrderState.orderPage.objects.find(
          (x) => x.type === 'multipleTickets',
        )?.min_total_participants,
      },
    };
    const calculatedPrice = calcPriceWithCustomDetails(tempDetails, multipleTicketsDetails, editOrderState.orderPage);
    setEditOrderState({
      ...editOrderState,
      calculatedPrice,
      details: {
        ...tempDetails,
        price: shouldKeepPrice ? keptPrice : calculatedPrice,
      },
    });
  };
  const setPersonalDetails = (personalDetails) => {
    setEditOrderState({ ...editOrderState, personalDetails });
  };
  const setOrderPage = (orderPageId) => {
    const newOrderPage = getOrderPages(account, true).find((o) => o.id === orderPageId);
    // some customers (speculo) need deafult custom details otherwise it breaks
    const defaultCustomDetails = OrderUtils.getDefaultCustomDetails(newOrderPage);
    const newInitialState = {
      ...initialState,
      account: editOrderState.account,
      experience: editOrderState.experience,
      orderPage: newOrderPage,
      extraPaymentsLines: editOrderState.extraPaymentsLines,
      details: {
        ...initialState.details,
        persons: editOrderState.details.persons,
        duration: newOrderPage.duration,
        custom_details: defaultCustomDetails,
      },
    };
    const calculatedPrice = calcPriceWithCustomDetails(newInitialState.details, defaultCustomDetails, newOrderPage);

    setEditOrderState({
      ...newInitialState,
      order_id: editOrderState.order_id,
      shouldAllowPriceOverride: !shouldKeepPrice,
      calculatedPrice,
      details: {
        ...newInitialState.details,
        price: shouldKeepPrice ? keptPrice : calculatedPrice,
      },
    });
  };
  const setDate = (date) => {
    const tempDetails = {
      ...editOrderState.details,
    };
    let shouldUseweekendPlugin = false;
    if (pluginEnabled(editOrderState.details?.custom_details, PluginType.WEEKEND_AND_ISRAELI_HOLIDAYS)) {
      shouldUseweekendPlugin = true;
    }
    const calculatedPrice = calcPrice(
      editOrderState.orderPage.price,
      tempDetails,
      {
        date: date.format(DATE_FORMAT),
      },
      editOrderState.orderPage.plugins,
      shouldUseweekendPlugin,
    );

    setEditOrderState({
      ...editOrderState,
      calculatedPrice,
      dateAndTimeDetails: {
        ...editOrderState.dateAndTimeDetails,
        date: date.format(DATE_FORMAT),
      },
      details: {
        ...tempDetails,
        price: shouldKeepPrice ? keptPrice : calculatedPrice,
      },
    });
  };
  const setPrice = (price) => {
    setEditOrderState({
      ...editOrderState,
      details: {
        ...editOrderState.details,
        price,
      },
    });
  };
  const { children } = props;
  return (
    <EditOrderContext.Provider
      value={{
        editOrderState,
        setOrderDetailValue,
        setCustomOrderDetailValue,
        setOrderCustomDetails,
        setEditOrderState,
        setPersonalDetails,
        setOrderPage,
        setDate,
        setPrice,
        setMultipleTicketsDetails,
      }}>
      {children}
    </EditOrderContext.Provider>
  );
};
