import React, { ReactNode } from 'react';
import {
  DndContext,
  DragEndEvent,
  MouseSensor,
  pointerWithin,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { EmployeesService, EnrichedEventData, OrderData, OrderPageTypes } from '../../../api';
import { UnifiedShiftAndUserAvailability } from '../../UnifiedShiftAndUserAvailability';
import { useCalendar } from '../../CalendarContext/CalendarContext';
import { useOrders } from '../../swr/useOrders';
import { useEvents } from '../../swr/useEvents';

interface DragDropProviderProps {
  children: ReactNode;
  orders: OrderData[];
  events: EnrichedEventData[];
  shiftAndAvailabilities: UnifiedShiftAndUserAvailability[];
}

const DragDropProvider: React.FC<DragDropProviderProps> = ({
  children,
  orders,
  shiftAndAvailabilities,
  events,
}: DragDropProviderProps) => {
  const { startDate, endDate } = useCalendar();

  const { updateItem: updateOrder } = useOrders({ startDate, endDate });
  const { updateItem: updateEvent } = useEvents({ startDate, endDate });
  const handleDragEnd = async ({ active, over }: DragEndEvent) => {
    let type = OrderPageTypes.EVENT;
    let event;

    const order = orders.find((x) => x.id === active.id);
    if (order) {
      type = OrderPageTypes.PRIVATE;
    } else {
      event = events.find((x) => x.id === active.id);
    }
    // trying to drop
    if (over) {
      const shiftAndAvailability = shiftAndAvailabilities.find((x) => x.id === over.id);
      if ((!order && !event) || !shiftAndAvailability) {
        return;
      }
      if (type === OrderPageTypes.PRIVATE) {
        if (!order) {
          return;
        }
        const updatedOrder = await EmployeesService.assignOrderToEmployee({
          order_id: order?.id,
          employee_id: shiftAndAvailability?.user_id,
        });
        updateOrder(updatedOrder);
      } else {
        if (!event) {
          return;
        }
        const updatedEvent = await EmployeesService.assignEventToEmployee({
          event_id: event.id,
          employee_id: shiftAndAvailability?.user_id,
        });
        updateEvent(updatedEvent);
      }
      // trying to remove from time
    } else {
      if (!order && !event) {
        return;
      }
      if (order) {
        const updatedOrder = await EmployeesService.assignOrderToEmployee({ order_id: order?.id });
        updateOrder(updatedOrder);
      } else if (event) {
        const updatedEvent = await EmployeesService.assignEventToEmployee({ event_id: event.id });
        updateEvent(updatedEvent);
      }
    }
  };
  const sensors = useSensors(
    useSensor(MouseSensor, { activationConstraint: { delay: 450, tolerance: { x: 500 } } }),
    useSensor(TouchSensor, {
      // Press delay is required for touch devices to avoid drag-and-drop conflict with scrolling
      activationConstraint: {
        delay: 150,
        tolerance: 5,
      },
    }),
  );
  return (
    <DndContext onDragEnd={handleDragEnd} collisionDetection={pointerWithin} sensors={sensors}>
      {children}
    </DndContext>
  );
};

export default DragDropProvider;
