import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import { Drawer, Grid, useMediaQuery, useTheme } from '@mui/material';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';

type TitledDrawerProps = {
  title: string;
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
  openAll?: any;
};
export default function TitledDrawer({ title, open, onClose, children, openAll }: TitledDrawerProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const handleClose = () => {
    onClose();
  };

  return (
    <div>
      <Drawer className="titled-drawer" disableRestoreFocus anchor="right" open={open} onClose={onClose}>
        <AppBar sx={{ position: 'relative' }} elevation={1}>
          <Toolbar>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid container xs={openAll ? 6 : undefined} alignItems="center">
                <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                  <CloseIcon />
                </IconButton>
                <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                  {title}
                </Typography>
              </Grid>
              {openAll && (
                <Grid item>
                  <Button
                    style={{ color: 'white', border: '1px solid white', textTransform: 'none' }}
                    onClick={openAll}>
                    {t('calendar.edit_order.openAll')}
                  </Button>
                </Grid>
              )}
            </Grid>
          </Toolbar>
        </AppBar>
        <Grid
          container
          flexDirection="column"
          overflow="hidden"
          height="100%"
          width={isSmallScreen ? undefined : '550px'}>
          {children}
        </Grid>
      </Drawer>
    </div>
  );
}
