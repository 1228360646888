import React from 'react';
import { Box } from '@mui/material';
import SliderUiObject from './SliderUiObject';
import ToggleButtonGroupUiObject from './ToggleButtonGroupUiObject';
import LocationWithOptionsUiObject from './LocationWithOptionsUiObject';
import SwitchUiObject from './SwitchUiObject';
import ToggleButtonGroupUiObjectDynamicPrice from './ToggleButtonGroupUiObjectDynamicPrice';
import TableUiObject from './TableUiObject';
import { MultipleTicketsUiObject } from './MultipleTicketsUiObject';

const UiObjectsList = (props) => {
  const {
    orderState,
    setOrderState,
    setOrderDetailValue,
    setCustomOrderDetailValue,
    setOrderCustomDetails,
    setMultipleTicketsDetails,
    type,
    errors,
    isLoadingDates,
    isEditOrder,
  } = props;

  return (
    <>
      {orderState.orderPage.objects.map((object) => {
        return (
          <Box key={object.id} id={object.id}>
            {object.type === 'slider' && !orderState.specificOrderPage?.onlyCouples && (
              <SliderUiObject
                type={type}
                uiObject={object}
                orderState={orderState}
                setOrderDetailValue={setOrderDetailValue}
                isLoadingDates={isLoadingDates}
              />
            )}
            {object.type === 'multipleTickets' && !orderState.specificOrderPage?.onlyCouples && (
              <MultipleTicketsUiObject
                uiObject={object}
                orderState={orderState}
                setMultipleTicketsDetails={setMultipleTicketsDetails}
                isLoadingDates={isLoadingDates}
              />
            )}
            {object.type === 'toggleButtonGroup' && (
              <ToggleButtonGroupUiObject
                key={object.id}
                uiObject={object}
                orderState={orderState}
                setOrderDetailValue={setOrderDetailValue}
                setCustomOrderDetailValue={setCustomOrderDetailValue}
                setOrderCustomDetails={setOrderCustomDetails}
              />
            )}
            {object.type === 'toggleButtonGroupDynamicPrice' && (
              <ToggleButtonGroupUiObjectDynamicPrice
                key={object.id}
                uiObject={object}
                orderState={orderState}
                setOrderDetailValue={setOrderDetailValue}
              />
            )}
            {object.type === 'locationWithOptions' && (
              <LocationWithOptionsUiObject
                key={object.id}
                uiObject={object}
                orderState={orderState}
                setOrderDetailValue={setOrderDetailValue}
                setCustomOrderDetailValue={setCustomOrderDetailValue}
                setOrderCustomDetails={setOrderCustomDetails}
                errors={errors}
              />
            )}

            {object.type === 'switch' && (
              <SwitchUiObject
                key={object.id}
                uiObject={object}
                orderState={orderState}
                setOrderDetailValue={setOrderDetailValue}
                setCustomOrderDetailValue={setCustomOrderDetailValue}
                errors={errors}
              />
            )}
            {object.type === 'tableDynamicPrice' && (
              <TableUiObject
                key={object.id}
                uiObject={object}
                orderState={orderState}
                setOrderState={setOrderState}
                setOrderDetailValue={setOrderDetailValue}
                errors={errors}
                isEditOrder={isEditOrder}
              />
            )}
          </Box>
        );
      })}
    </>
  );
};

export default UiObjectsList;
