import { Grid, TableCell, TableRow as MuiTableRow } from '@mui/material';
import React from 'react';
import { CellProps } from './useTable';
import { PriceCell } from './PriceCell';
import { OrderData, VoucherOut } from '../api';
import { TABLE_PHONE_ID, TABLE_PRICE_ID } from './TableComponent';
import { UseAuthPermissions } from '../session/UseAuthPermissions';
import { PhoneCell } from './PhoneCell';

type TableRowProps<T> = {
  index: number;
  tableDetails: CellProps<T>[];
  item: T;
};
const renderCellContent = <T,>(cellProps: CellProps<T>, item: T, isVoucher: boolean): React.ReactNode => {
  if (cellProps.id === TABLE_PRICE_ID) {
    return <PriceCell orderOrVoucher={item as OrderData | VoucherOut} isVoucher={isVoucher} />;
  }

  if (cellProps.hideCell) {
    return null;
  }

  if (cellProps.id === TABLE_PHONE_ID) {
    return <PhoneCell phone={(item as any).phone} />;
  }

  if (cellProps.render) {
    return cellProps.render(item);
  }

  throw new Error(`Missing render function for cell with id: ${cellProps.id}`);
};
export const TableRow = <T,>({ index, tableDetails, item }: TableRowProps<T>) => {
  const isVoucher = (item as VoucherOut).code !== undefined;
  const { readOnly } = UseAuthPermissions();

  return (
    <MuiTableRow key={`tablerow-${index}`}>
      {tableDetails.map((cellProps) =>
        cellProps.id === TABLE_PRICE_ID && readOnly ? null : (
          <TableCell
            align={cellProps.alignment}
            id={`row${index}cell${cellProps.id}`}
            sx={{
              minWidth: cellProps.width,
              maxWidth: cellProps.width,
              padding: cellProps.noPadding ? '0px' : undefined,
            }}>
            <Grid container justifyContent="center">
              {renderCellContent(cellProps, item, isVoucher)}
            </Grid>
          </TableCell>
        ),
      )}
    </MuiTableRow>
  );
};
