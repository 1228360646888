import React from 'react';
import { Grid, TableCell, TableRow, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import IconButton from '@mui/material/IconButton';
import { Delete, Edit, Loop, Update, WhereToVote } from '@mui/icons-material';
import '../../../components/EventParticipantTable.scss';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import Check from '@mui/icons-material/Check';
import { useCalendar } from '../../../CalendarContext/CalendarContext';
import TextWithTooltip from '../../../../common/ui/TextWithTooltip';
import { UseAuthPermissions } from '../../../../session/UseAuthPermissions';
import {
  CustomDetailData,
  CustomDetailTypes,
  CustomPersonalDetailData,
  CustomPersonalDetailTypes,
  OrderData,
} from '../../../../api';
import { whatsAppLinkFormatter } from '../../../../common/whatsAppLinkFormatter';
import CopyToClipboardButton from '../../../dialogs/CopyToClipboardButton';
import { usePriceRenderer } from '../../../../common/usePriceRenderer';

type EventParticipantsRowProps = {
  order: OrderData;
  index: number;
  setOrder: (order: OrderData) => void;
  removeOrder: (order: OrderData) => void;
  setEditOrderIsOpen: (isOpen: boolean) => void;
  setCheckInDialogIsOpen: (isOpen: boolean) => void;
  orderPageCustomPersonalDetails: CustomPersonalDetailData[];
  orderCustomDetails: CustomDetailData[];
  isInEventMode: boolean;
};
const EventParticipantsRow = ({
  order,
  index,
  setOrder,
  setEditOrderIsOpen,
  setCheckInDialogIsOpen,
  removeOrder,
  orderPageCustomPersonalDetails,
  orderCustomDetails,
  isInEventMode,
}: EventParticipantsRowProps) => {
  const { openDeleteOrderDialog } = useCalendar();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const { t } = useTranslation();
  const { readOnly } = UseAuthPermissions();
  const selfServeEditsEnabled = useFeatureFlagEnabled('self-serve-edits');
  const { SlimPrice } = usePriceRenderer({
    orderId: order.id,
    discountId: order.discount_id,
    initialPrice: order.price,
    returningCustomerFromOrderId: order.returning_customer_from_order_id,
    extraPaymentLines: order.extra_payment_lines,
  });
  const isPartiallyCheckedIn = (order: OrderData) =>
    order.checkin && order.checkin.persons > 0 && order.checkin.persons < order.persons;
  // Added pagination here as front finds it hard to show more then few 10s of lines and starts hanging

  return (
    <TableRow
      key={`participantsTable-${index}`}
      className={`table-row${order.checkin && order.checkin.persons === order.persons ? ' checkin-row' : ''}${
        isPartiallyCheckedIn(order) ? ' partially-checkin-row' : ''
      }`}>
      <TableCell align="left" sx={{ padding: '0!important' }}>
        <IconButton
          sx={{ marginInline: isInEventMode ? '20px' : undefined }}
          onClick={() => {
            setOrder(order);
            setCheckInDialogIsOpen(true);
          }}>
          <Tooltip title={t('checkin')} arrow>
            <WhereToVote
              style={{ width: isInEventMode ? '32px' : undefined, height: isInEventMode ? '32px' : undefined }}
            />
          </Tooltip>
        </IconButton>
      </TableCell>
      {!isInEventMode && (
        <>
          <TableCell align="left" sx={{ padding: '0!important' }}>
            <Tooltip title={readOnly ? t('noPermissions') : t('delete')} arrow>
              <span>
                <IconButton
                  style={{ padding: '0px' }}
                  disabled={readOnly}
                  onClick={() => {
                    openDeleteOrderDialog({ order, onSubmitCallback: () => removeOrder(order) });
                  }}>
                  <Delete />
                </IconButton>
              </span>
            </Tooltip>
          </TableCell>
          <TableCell align="left" sx={{ padding: '0!important' }}>
            <Tooltip title={readOnly ? t('noPermissions') : t('edit')} arrow>
              <span>
                <IconButton
                  style={{ padding: '0px' }}
                  disabled={readOnly}
                  onClick={() => {
                    setOrder(order);
                    setEditOrderIsOpen(true);
                  }}>
                  <Edit />
                </IconButton>
              </span>
            </Tooltip>
          </TableCell>
          <TableCell align="left" sx={{ padding: '0!important' }}>
            <a
              href={
                !readOnly && selfServeEditsEnabled
                  ? `${window.location.origin}/manage-booking?orderid=${order.id}`
                  : undefined
              }
              target="_blank"
              rel="noopener noreferrer">
              <Tooltip
                title={readOnly ? t('noPermissions') : selfServeEditsEnabled ? t('amend') : t('amend_not_enabled')}
                arrow>
                <span>
                  <IconButton disabled={readOnly} style={{ padding: '0px' }}>
                    <Update />
                  </IconButton>
                </span>
              </Tooltip>
            </a>
          </TableCell>
        </>
      )}
      <TableCell align="left" sx={{ paddingInlineStart: '16px!important' }}>
        <Grid container alignItems="center" gap={1}>
          {order.returning_customer && (
            <Tooltip title={t('returnningCustomer')} arrow>
              <Loop style={{ color: 'green' }} />
            </Tooltip>
          )}
          <Grid
            item
            style={{
              whiteSpace: 'normal',
              overflowWrap: 'break-word',
            }}
            maxWidth={isSmallScreen ? '120px' : '200px'}>
            {`${order.firstname} ${order.lastname}`}
          </Grid>
        </Grid>
      </TableCell>
      {orderCustomDetails.map((customDetail: CustomDetailData) => {
        if (customDetail.type === CustomDetailTypes.BOOL) {
          const detailIsTrue = order.custom_details[customDetail.name] === true;
          return <TableCell align="center">{detailIsTrue && <Check />}</TableCell>;
        }
        const detail = order.custom_details[customDetail.name];
        return (
          <TableCell
            align="left"
            style={{
              whiteSpace: 'normal',
              overflowWrap: 'break-word',
              maxWidth: isSmallScreen ? '120px' : '200px',
            }}>
            <Typography />
            {detail}
          </TableCell>
        );
      })}
      <TableCell align="center">
        {isPartiallyCheckedIn(order) ? `${order.checkin?.persons}/${order.persons}` : order.persons}
      </TableCell>

      {orderPageCustomPersonalDetails
        .filter((x: CustomPersonalDetailData) => !x.hide_from_console)
        .map((customDetail: CustomPersonalDetailData) => {
          if (customDetail.type === CustomPersonalDetailTypes.BOOLEAN) {
            const detailIsTrue = order.custom_personal_details[customDetail.id] === true;
            return <TableCell align="center">{detailIsTrue && <Check />}</TableCell>;
          }
          return (
            <TableCell
              align="left"
              style={{
                whiteSpace: 'normal',
                overflowWrap: 'break-word',
                maxWidth: isSmallScreen ? '120px' : '200px',
              }}>
              <Typography />
              {order.custom_personal_details[customDetail.id]}
            </TableCell>
          );
        })}
      <TableCell align="left">
        {order.comment && (
          <TextWithTooltip width={isSmallScreen ? '120px' : '200px'} text={order.comment} tooltip={order.comment} />
        )}
      </TableCell>
      <TableCell align="left">
        <Grid container gap={1} alignItems="center">
          <CopyToClipboardButton width={'18px'} color="grey" text={order.phone} />

          <a className="link" target="_blank" href={whatsAppLinkFormatter(order.phone, '')} rel="noreferrer">
            {order.phone}
          </a>
        </Grid>
      </TableCell>
      <TableCell align="left">
        <SlimPrice />
      </TableCell>

      <TableCell align="left">{t(`order_status.${order.status.toLowerCase()}`)}</TableCell>
    </TableRow>
  );
};

export default EventParticipantsRow;
