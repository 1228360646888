import { Grid, styled, Table, TableBody, TableCell, TableHead, TableRow as MuiTableRow } from '@mui/material';
import TablePagination from '@mui/material/TablePagination';
import React from 'react';
import { useTranslation } from 'react-i18next';
import RotatingLoader from '../common/ui/RotatingLoader';
import { TableRow } from './TableRow';
import { CellProps } from './useTable';
import { UseAuthPermissions } from '../session/UseAuthPermissions';
// it is done so we can omit price column for readonly users
export const TABLE_PRICE_ID = 'price';
export const TABLE_PHONE_ID = 'phone';

const StyledTable = styled(Table)(() => ({
  whiteSpace: 'pre',
  '& thead': {
    '& tr': { '& th': { paddingLeft: 0, paddingRight: 0 } },
  },
  '& tbody': {
    '& tr': { '& td': { paddingLeft: 0, textTransform: 'capitalize' } },
  },
}));

type TableProps<T> = {
  isLoading: boolean;
  data?: T[];
  tableDetails: CellProps<T>[];

  hidePaging?: boolean;
  page: number;
  setPage: (page: number) => void;
  rowsPerPage: number;
  setRowsPerPage: (rowsPerPage: number) => void;
};

export function TableComponent<T>({
  isLoading,
  data,
  tableDetails,
  hidePaging,
  page,
  rowsPerPage,
  setRowsPerPage,
  setPage,
}: TableProps<T>) {
  const { t } = useTranslation();
  const { readOnly } = UseAuthPermissions();

  const handleRowsPerPageChange = (event: any) => {
    const newRowsPerPage = +event.target.value;
    setRowsPerPage(newRowsPerPage);
    setPage(0);
  };
  const handlePageChange = (_: any, newPage: number) => {
    setPage(newPage);
  };

  return isLoading ? (
    <Grid
      container
      sx={{
        minHeight: '200px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
      <RotatingLoader />
    </Grid>
  ) : (
    <Grid container>
      <Grid overflow="scroll" justifyContent="flex-start" container>
        <StyledTable size="small" stickyHeader>
          <TableHead>
            <MuiTableRow>
              {tableDetails.map((item) =>
                item.id === TABLE_PRICE_ID && readOnly ? null : (
                  <TableCell align="center" width={item.width ? `${item.width}px` : undefined} sx={{ fontWeight: 700 }}>
                    <Grid container px={1} justifyContent="center">
                      {item.label}
                    </Grid>
                  </TableCell>
                ),
              )}
            </MuiTableRow>
          </TableHead>
          <TableBody>
            {data?.map((item: T, index: number) => (
              <TableRow index={index} tableDetails={tableDetails} item={item} />
            ))}
          </TableBody>
        </StyledTable>
      </Grid>
      {!hidePaging && (
        <Grid container justifyContent="flex-end">
          <TablePagination
            sx={{ px: 2 }}
            page={page}
            component="div"
            rowsPerPage={rowsPerPage}
            count={-1}
            onPageChange={handlePageChange}
            rowsPerPageOptions={[5, 10, 25]}
            onRowsPerPageChange={handleRowsPerPageChange}
            labelRowsPerPage={t('orders.rows_per_page')}
            labelDisplayedRows={() => page}
            nextIconButtonProps={{ 'aria-label': 'Next Page', disabled: data && data.length < rowsPerPage }}
            backIconButtonProps={{ 'aria-label': 'Previous Page' }}
          />
        </Grid>
      )}
    </Grid>
  );
}
