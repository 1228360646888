import { useState } from 'react';

export type OpenableDrawer = { isOpen: boolean };

export type UseEditEntityDrawerReturnType<T extends OpenableDrawer> = {
  drawerState: T & OpenableDrawer;
  open: (params: Omit<T, 'isOpen'>) => void;
  close: () => void;
};

export const useEditEntityDrawer = <T extends OpenableDrawer>(initialState: T): UseEditEntityDrawerReturnType<T> => {
  const [drawerState, setDrawerState] = useState<T>(initialState);

  const open = (params: Omit<T, 'isOpen'>) => {
    setDrawerState({
      ...drawerState,
      isOpen: true,
      ...params,
    });
  };

  const close = () => {
    setDrawerState({
      // ...drawerState, todo: should be good to clean state on drawer close..
      ...initialState,
      isOpen: false,
    });
  };

  return { drawerState, open, close };
};
