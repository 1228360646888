export const userColorPalette = [
  '#609608', // Vibrant Red
  '#1932b0', // Vibrant Green
  '#a81875', // Vibrant Blue
  '#6e560a', // Vibrant Pink
  '#071d64', // Vibrant Cyan
  '#FFC233', // Vibrant Yellow
  '#A833FF', // Vibrant Purple
  '#FF8333', // Vibrant Orange
  '#1e4604', // Vibrant Mint
  '#FF338F', // Vibrant Magenta
  '#15330f', // Vibrant Lime
  '#8A33FF', // Vibrant Violet
  '#FF5733', // Vibrant Coral
  '#33A8FF', // Vibrant Sky Blue
  '#ff33b8', // Vibrant Lemon
  '#FF5733', // Vibrant Sunset
  '#57FFA8', // Vibrant Teal
  '#FF338F', // Vibrant Rose
  '#5733FF', // Vibrant Indigo
  '#FFA833', // Vibrant Apricot
];
