import { Button, Checkbox, FormControlLabel, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { KeyedMutator } from 'swr/_internal';
import { AccountData, AccountService, UpdateAccountGeneralInput } from '../api';

type AccountGeneralSettingsProps = {
  account: AccountData;
  updateLocalAccount: KeyedMutator<AccountData>;
  setIsUpdatedSnackOpen: (value: boolean) => void;
};
export const AccountGeneralSettings = ({
  account,
  updateLocalAccount,
  setIsUpdatedSnackOpen,
}: AccountGeneralSettingsProps) => {
  const { t } = useTranslation();
  const [isUpdatingSettings, setIsUpdatingSettings] = React.useState(false);

  const initialValues: UpdateAccountGeneralInput = {
    should_send_waiting_list_notifications: account?.should_send_waiting_list_notifications || false,
  };

  const validationSchema = yup.object().shape({
    should_send_waiting_list_notifications: yup.boolean().required(),
  });

  const onSubmit = async (values: UpdateAccountGeneralInput) => {
    setIsUpdatingSettings(true);
    const updatedAccount = await AccountService.generalSettings(values);
    await updateLocalAccount(updatedAccount);
    setIsUpdatingSettings(false);
    setIsUpdatedSnackOpen(true);
  };

  return (
    <Formik enableReinitialize initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({ values, handleChange, handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          <Grid container p={0} flexWrap="nowrap" alignItems="flex-start">
            <Grid container flexDirection="column">
              <Grid item mb={2}>
                <Typography variant="h4">{t('accountSettings.generaltitle')}</Typography>
              </Grid>
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="should_send_waiting_list_notifications"
                      checked={values.should_send_waiting_list_notifications}
                      onChange={handleChange}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  }
                  label={<Typography>{t('accountSettings.shouldSendWaitlist')}</Typography>}
                />
              </Grid>
            </Grid>
            <Grid container justifyContent="flex-end" mt={2} xs={2}>
              <Button type="submit" variant="contained" disabled={isUpdatingSettings}>
                {t('save')}
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};
