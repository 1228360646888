import moment from 'moment/moment';
import { CalendarContextType } from './CalendarContextType';
import { DATE_FORMAT } from '../consts';

export const initialCalendarContextState: CalendarContextType = {
  openEditOrderDrawer: () => {},
  closeEditOrderDrawer: () => {},
  editOrderDrawerState: { isOpen: false },

  openExperienceAvailabilityDrawer: () => {},
  closeExperienceAvailabilityDrawer: () => {},
  experienceAvailabilityDrawerState: { isOpen: false },

  openDeleteOrderDialog: () => {},
  closeDeleteOrderDialog: () => {},
  deleteOrderDialogState: { isOpen: false },

  openDeleteEventDialog: () => {},
  closeDeleteEventDialog: () => {},
  deleteEventDialogState: { isOpen: false },

  openDeleteUnavailabilityDialog: () => {},
  closeDeleteUnavailabilityDialog: () => {},
  deleteUnavailabilityDialogState: { isOpen: false },

  openDeleteShiftDialog: () => {},
  closeDeleteShiftDialog: () => {},
  deleteShiftDialogState: { isOpen: false },

  openEditShiftDialog: () => {},
  closeEditShiftDialog: () => {},
  editShiftDialogState: { isOpen: false },

  users: [],
  setUsers: () => {},

  startDate: moment().format(DATE_FORMAT),
  endDate: moment().format(DATE_FORMAT),
  setStartDate: () => {},
  setEndDate: () => {},

  userColors: {},
  selectedUsersIds: [],
  setSelectedUsersIds: () => {},
  shouldBlockSwiping: false,
  setShouldBlockSwiping: () => {},

  isAnyThingOpen: false,
};
