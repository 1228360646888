import { Grid, IconButton, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import moment from 'moment';
import { Edit } from '@mui/icons-material';
import Typography from '@mui/material/Typography';
import { isEmpty } from 'lodash';
import { AccountData, DiscountOut, DiscountService, DiscountStatus } from '../api';
import { CellProps, useTable } from '../table/useTable';
import { DiscountsActions } from './DiscountsActions';
import { UseAuthPermissions } from '../session/UseAuthPermissions';
import { EditDiscountDialog } from './EditDiscountDialog';
import { DiscountStatusCircle } from './DiscountStatusCircle';
import { getDateFormat } from '../common/getDateAndTimeFormat';
import { useAuth } from '../session/InternalAuthProvider';
import { getOrderPages } from '../common/AccountUtils';
import { WorkshopCircle } from '../common/ui/WorkshopCircle';
import { useLanguage } from '../common/GeneralUtils';
import { InfoWithTooltip } from '../common/ui/InfoWithTooltip';

export const discountsFetcher = async ({ search, page, perPage }: { search?: string; page: number; perPage: number }) =>
  DiscountService.getDiscounts(page, perPage, search);
type DiscountsPageContentProps = {
  editDialogIsOpen: boolean;
  setEditDialogIsOpen: (value: boolean) => void;
};
export const DiscountsPageContent = ({ editDialogIsOpen, setEditDialogIsOpen }: DiscountsPageContentProps) => {
  // @ts-ignore
  const { authState } = useAuth();
  const account = authState.account as unknown as AccountData;
  const language = useLanguage();

  const { readOnly } = UseAuthPermissions();
  const { t } = useTranslation();
  const [selectedDiscount, setSelectedDiscount] = useState<DiscountOut | undefined>(undefined);
  const { longDateFormat, shortDateFormat } = getDateFormat(account);
  const orderPages = getOrderPages(account);

  function discountIsActive(discount: DiscountOut) {
    if (discount.status === DiscountStatus.NOT_ACTIVE) {
      return false;
    }
    if (
      moment().startOf('days') > moment(discount.end_date) ||
      moment() < moment(discount.start_date).startOf('days')
    ) {
      return false;
    }
    return true;
  }

  const tableDetails: CellProps<DiscountOut>[] = [
    {
      id: 'description',
      alignment: 'center',
      label: t('discounts.description'),
      render: (discount: DiscountOut) => discount.description,
    },
    {
      id: 'value',
      alignment: 'center',
      label: t('discounts.value'),
      render: (discount: DiscountOut) => `${discount.value}%`,
    },
    {
      id: 'code',
      alignment: 'center',
      label: t('discounts.code'),
      render: (discount: DiscountOut) => <Typography style={{ textTransform: 'none' }}>{discount.code}</Typography>,
    },
    {
      id: 'workshops',
      alignment: 'center',
      label: t('discounts.workshops'),
      render: (discount: DiscountOut) =>
        isEmpty(discount.order_page_ids) || discount.order_page_ids?.length === orderPages.length ? (
          t('discounts.allWorkshops')
        ) : (
          <Grid container gap={0.5} justifyContent="center">
            {discount.order_page_ids?.map((x) => (
              <WorkshopCircle account={account} orderPageId={x} language={language} withTooltip />
            ))}
          </Grid>
        ),
    },
    {
      id: 'validity',
      alignment: 'center',
      label: (
        <Grid container gap={1}>
          {t('discounts.validity')}
          <InfoWithTooltip title={t('discounts.validityDescription')} />
        </Grid>
      ),
      render: (discount: DiscountOut) =>
        `${moment(discount.start_date).format(longDateFormat)} - ${moment(discount.end_date).format(longDateFormat)}`,
    },
    {
      id: 'usedcount',
      alignment: 'center',
      label: t('discounts.usedCount'),
      render: (discount: DiscountOut) => discount.used_count,
    },
    {
      id: 'usedLimit',
      alignment: 'center',
      label: t('discounts.usedLimit'),
      render: (discount: DiscountOut) => (discount.should_limit_usage ? discount.limit_usage_count : ''),
    },
    {
      id: 'resvalidity',
      alignment: 'center',
      label: (
        <Grid container gap={1}>
          {t('discounts.resvalidity')}
          <InfoWithTooltip title={t('discounts.resvalidityDescription')} />
        </Grid>
      ),
      render: (discount: DiscountOut) => {
        if (discount.redemption_min_date === null && discount.redemption_max_date === null) {
          return '-';
        }
        if (discount.redemption_min_date === null) {
          return `${t('discounts.until')} ${moment(discount.redemption_max_date).format(shortDateFormat)}`;
        }
        if (discount.redemption_max_date === null) {
          return `${t('discounts.from')} ${moment(discount.redemption_min_date).format(shortDateFormat)}`;
        }
        return `${moment(discount.redemption_min_date).format(shortDateFormat)} - ${moment(
          discount.redemption_max_date,
        ).format(shortDateFormat)}`;
      },
    },
    {
      id: 'voucherOnly',
      alignment: 'center',
      label: t('discounts.voucherOnly'),
      render: (discount: DiscountOut) => !!discount.is_valid_only_for_vouchers && 'V',
    },
    {
      id: 'oncePerEmail',
      alignment: 'center',
      label: t('discounts.oncePerEmail'),
      render: (discount: DiscountOut) => !!discount.is_valid_for_only_once_per_email && 'V',
    },
    {
      id: 'status',
      alignment: 'center',
      label: t('discounts.status'),
      render: (discount: DiscountOut) => <DiscountStatusCircle discountIsActive={discountIsActive(discount)} />,
    },
    {
      id: 'edit',
      alignment: 'left',
      width: 30,
      noPadding: true,
      render: (disount: DiscountOut) => (
        <Tooltip title={readOnly ? t('noPermissions') : t('edit')}>
          <span>
            <IconButton
              disabled={readOnly}
              className="action-icon"
              onClick={() => {
                setSelectedDiscount(disount);
                setEditDialogIsOpen(true);
              }}>
              <Edit />
            </IconButton>
          </span>
        </Tooltip>
      ),
    },
  ];

  const { mutate, data, TableComponent } = useTable<DiscountOut>({
    actionsComponent: <DiscountsActions onAdd={(newDiscount: DiscountOut) => mutate([...(data || []), newDiscount])} />,
    defaultRowsPerPage: 10,
    opId: 'getDiscounts',
    fetcher: discountsFetcher,
    tableDetails,
    revalidateOnFocus: !editDialogIsOpen,
  });
  return (
    <Grid container>
      {TableComponent}
      {editDialogIsOpen && (
        <EditDiscountDialog
          dialogIsOpen={editDialogIsOpen}
          setDialogIsOpen={setEditDialogIsOpen}
          discount={selectedDiscount}
          onUpdate={(updatedDiscount: DiscountOut) =>
            mutate([...(data || []).filter((x) => x.id !== updatedDiscount.id), updatedDiscount])
          }
        />
      )}
    </Grid>
  );
};
