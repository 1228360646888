import { useEditEntityDrawer, UseEditEntityDrawerReturnType } from '../UseEditEntityDrawer';
import { EnrichedEventData, PrivateSlotOut, UnavailabilityData } from '../../../api';

export type ExperienceAvailabilityDrawerState = {
  isOpen: boolean;
  unavailability?: UnavailabilityData;
  privateSlotAvailability?: PrivateSlotOut;
  event?: EnrichedEventData;
  orderPageId?: string;
};

export const useExperienceAvailabilityDrawer = (): UseEditEntityDrawerReturnType<ExperienceAvailabilityDrawerState> => {
  return useEditEntityDrawer<ExperienceAvailabilityDrawerState>({ isOpen: false });
};
