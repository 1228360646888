import { useEditEntityDrawer, UseEditEntityDrawerReturnType } from '../UseEditEntityDrawer';
import { ShiftOut } from '../../../api';

export type ShiftDialogState = {
  isOpen: boolean;
  shift?: ShiftOut;
  createNewShiftPayload?: {
    startTime: number;
    endTime: number;
    userId: string;
    description?: string;
    date: string;
  };
};

export const useShiftDialog = (): UseEditEntityDrawerReturnType<ShiftDialogState> => {
  return useEditEntityDrawer<ShiftDialogState>({ isOpen: false });
};
