import { Card, CardContent, CardMedia, Checkbox, Chip, Divider, Grid, Typography } from '@mui/material';
import { BugReport, Link as MuiLinkIcon, Settings, Store } from '@mui/icons-material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import IconButton from '@mui/material/IconButton';
import { Link } from 'react-router-dom';
import { useAuth } from '../session/InternalAuthProvider';
import { useLanguage } from '../common/GeneralUtils';
import { OrderPageData, OrderPageTypes } from '../api';
import CopyToClipboardButton from '../calendar/dialogs/CopyToClipboardButton';
import TTLLocalStorage from '../common/TTLLocalStorage';

type OrderPageCardProps = {
  orderPage: OrderPageData;
  isSelected: boolean;
  onSelect: (orderPageId: string) => void;
};
const OrderPageCard = ({ orderPage, isSelected, onSelect }: OrderPageCardProps) => {
  // @ts-ignore
  const { authState } = useAuth();
  const language = useLanguage();
  const { t } = useTranslation();
  // <Link to={`/dashboard/settings/${orderPage.id}`} style={{ textDecoration: 'none', display: 'flex' }}>
  // @ts-ignore
  const baseLink = `/order/${authState?.account?.id}/${authState.account?.experiences[0].id}/`;
  const bookingPageLink = `${window.location.origin}${baseLink}`;
  return (
    <Card sx={{ maxWidth: '300px', position: 'relative', border: '1px solid #3333' }}>
      <Grid container height={48} justifyContent={'space-between'} alignItems={'center'}>
        <Grid item>
          <Checkbox checked={isSelected} onChange={() => onSelect(orderPage.id)} />
        </Grid>
        <Grid item px={1.5}>
          <Chip
            sx={{
              backgroundColor: orderPage.order_page_type === OrderPageTypes.PRIVATE ? '#2B334D' : '#67AD5B',
              color: 'white',
            }}
            label={
              <Typography fontWeight="700">
                {orderPage.order_page_type === OrderPageTypes.PRIVATE
                  ? t('order_pages.private')
                  : t('order_pages.join')}
              </Typography>
            }
          />
        </Grid>
      </Grid>
      <CardMedia
        onClick={() => onSelect(orderPage.id)}
        component="img"
        height="280px"
        image={
          orderPage.photo?.replace('raw', 'image').concat('?w=300&h=280&fit=crop') || '/images/order-page-pic.jpeg'
        }
        sx={{ borderBottom: '1px solid #eee' }}
      />

      <CardContent style={{ height: '280px' }}>
        <Grid container flexDirection="column" gap={2} style={{ height: '100%' }} justifyContent="space-between">
          <Grid container flexDirection="column">
            <Typography gutterBottom variant="h5" component="div">
              {orderPage.label[language]}
            </Typography>
          </Grid>
          <Grid container flexDirection="column">
            <Divider />
            <Grid container flexDirection="column">
              <Grid container alignItems="center">
                <Link className="no-style-link" to={bookingPageLink + orderPage.id} target="_blank">
                  <IconButton>
                    <Store fontSize="small" />
                  </IconButton>
                </Link>

                <Link
                  className="no-style-link"
                  to={bookingPageLink + orderPage.id}
                  target="_blank"
                  onClick={() => TTLLocalStorage.removeByPrefix('order-page-state-')}>
                  <Typography variant="body2" color="text.secondary">
                    {t('order_pages.order_page')}
                  </Typography>
                </Link>

                <Grid item display="flex" mx={'2px'}>
                  <CopyToClipboardButton
                    orderPageId={orderPage.id}
                    text={bookingPageLink + orderPage.id}
                    IconComponent={MuiLinkIcon}
                    color="grey"
                  />
                </Grid>
              </Grid>
              <Grid container alignItems="center">
                <Link className="no-style-link" to={`/dashboard/settings/${orderPage.id}`}>
                  <Grid container alignItems="center">
                    <IconButton>
                      <Settings fontSize="small" />
                    </IconButton>
                    <Typography variant="body2" color="text.secondary">
                      {t('order_pages.settings')}
                    </Typography>
                  </Grid>
                </Link>
              </Grid>
              <Grid container alignItems="center">
                <Link className="no-style-link" to={`${bookingPageLink}${orderPage.id}?nomindate=true`} target="_blank">
                  <IconButton>
                    <Store fontSize="small" />
                  </IconButton>
                </Link>
                <Link className="no-style-link" to={`${bookingPageLink}${orderPage.id}?nomindate=true`} target="_blank">
                  <Typography variant="body2" color="text.secondary">
                    {t('order_pages.order_page_today')}
                  </Typography>
                </Link>
                <Grid item display="flex" mx={'2px'}>
                  <CopyToClipboardButton
                    orderPageId={orderPage.id}
                    text={`${bookingPageLink}${orderPage.id}?nomindate=true`}
                    IconComponent={MuiLinkIcon}
                    color="grey"
                  />
                </Grid>
              </Grid>
              <Grid container alignItems="center">
                <Link
                  className="no-style-link"
                  to={`${bookingPageLink}${orderPage.id}?troubleshoot=true`}
                  target="_blank">
                  <IconButton>
                    <BugReport fontSize="small" />
                  </IconButton>
                </Link>
                <Link
                  className="no-style-link"
                  to={`${bookingPageLink}${orderPage.id}?troubleshoot=true`}
                  target="_blank">
                  <Typography variant="body2" color="text.secondary">
                    {t('order_pages.troubleshoot')}
                  </Typography>
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default OrderPageCard;
