import { Box, Stack, ThemeProvider, useTheme } from '@mui/material';
import { Add, Remove } from '@mui/icons-material';
import './NumberSelector.scss';
import { CacheProvider } from '@emotion/react';
import { cacheLtr } from '../GeneralUtils';
import { OrderPageButton } from '../../order_page/themedComponents/OrderPageButton';

type NumberSelectorProps = {
  value: number;
  setValue: (value: number) => void;
  min: number;
  max?: number;
  disabled?: boolean;
  isAddButtonDisabled?: boolean;
  isRemoveDisabled?: boolean;
};
const NumberSelector = ({
  value,
  setValue,
  min,
  max,
  disabled,
  isAddButtonDisabled,
  isRemoveDisabled,
}: NumberSelectorProps) => {
  const theme = useTheme();
  const changeVal = (val: number) => {
    if (!!max && val > max) {
      val = max;
    }
    if (val < min) {
      val = min;
    }
    setValue(val);
  };

  return (
    <CacheProvider value={cacheLtr}>
      <ThemeProvider theme={{ ...theme, direction: 'ltr' }}>
        <Stack spacing={1} direction="row" className="number-selector">
          <OrderPageButton
            className="number-button"
            onClick={() => changeVal(value - 1)}
            variant="contained"
            disabled={disabled || isRemoveDisabled}
            $disabled={disabled || isRemoveDisabled}>
            <Remove style={{ color: theme.customTheme.palette.background }} />
          </OrderPageButton>

          <Box className="value-box">{value}</Box>

          <OrderPageButton
            className="number-button"
            onClick={() => changeVal(value + 1)}
            variant="contained"
            disabled={disabled || isAddButtonDisabled}>
            <Add style={{ color: theme.customTheme.palette.background }} />
          </OrderPageButton>
        </Stack>
      </ThemeProvider>
    </CacheProvider>
  );
};

export default NumberSelector;
