import { Grid, TextField } from '@mui/material';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ParallelPersonsCapacitiesSetting } from './ParallelPersonsCapacitiesSetting';
import { CAPACITIES_BLOCKS_ARRAY, MAX_PARALLEL_PERSON_CAPACITY, STATIONS } from './PrivateUnavailabilityDrawer';
import { useAuth } from '../../../../session/InternalAuthProvider';
import { AccountData, OrderPageData } from '../../../../api';
import { getOrderPages } from '../../../../common/AccountUtils';
import { useLanguage } from '../../../../common/GeneralUtils';

type RenderCustomDetailsProps = {
  orderPageId: string;
  customDetails: any;
  updateCustomDetails: (order_page_id: string, key: string, value: any) => void;
};
export const RenderCustomDetails = ({ orderPageId, customDetails, updateCustomDetails }: RenderCustomDetailsProps) => {
  const { t } = useTranslation();
  const language = useLanguage();

  const { authState } = useAuth();
  const account = authState.account as unknown as AccountData;
  const orderPages = getOrderPages(account);
  const orderPage = orderPages.find((x: OrderPageData) => x.id === orderPageId);
  if (!orderPage) {
    return null;
  }
  const customDetail =
    orderPage?.availability_settings?.restrictions_obj?.max_parallel_person_capacity?.parallel_persons_capacities;
  // const unavailabilityDetail = unavailabilityDetails.find((x) => x.order_page_id === orderPageId);
  const hasLegacyCustomDetail = customDetails?.[MAX_PARALLEL_PERSON_CAPACITY];
  const customResourceDetail = orderPage.custom_details?.find((c: { cls: string }) => c.cls === 'CustomResourceDetail');
  if (!customDetail && !customResourceDetail) {
    return null;
  }
  if (customResourceDetail) {
    // @ts-ignore
    const { max, label: labelData } = customResourceDetail;
    // @ts-ignore
    const label = labelData[language];
    // @ts-ignore
    const value = customDetails?.[customResourceDetail.name];
    if (!value) {
      updateCustomDetails(orderPageId, STATIONS, 3);
    }
    return (
      <Grid container gap={1} alignItems="center" py={1}>
        <Grid item>
          <Typography variant="body1">{label}</Typography>
        </Grid>
        <TextField
          size="small"
          type="number"
          InputProps={{
            inputProps: { min: 0, max },
          }}
          value={value}
          onChange={(e) => updateCustomDetails(orderPageId, customResourceDetail?.name, e.target.value)}
        />
      </Grid>
    );
  }
  if (hasLegacyCustomDetail) {
    return (
      <Grid container sx={{ marginTop: '30px' }} gap={3} alignItems="center">
        <TextField
          label={t('availabilitySettings.blockResources')}
          sx={{ width: '120px' }}
          type="number"
          value={customDetails?.[MAX_PARALLEL_PERSON_CAPACITY]}
          InputProps={{
            inputProps: { min: 0, max: customDetail?.length },
          }}
          onChange={(e) => updateCustomDetails(orderPageId, MAX_PARALLEL_PERSON_CAPACITY, +e.target.value)}
        />
      </Grid>
    );
  }

  return (
    <ParallelPersonsCapacitiesSetting
      orderPageData={orderPage}
      initialBlockedCapacities={customDetails?.[CAPACITIES_BLOCKS_ARRAY] || customDetail}
      setDetail={(blockedCapacities: number[]) =>
        updateCustomDetails(orderPageId, CAPACITIES_BLOCKS_ARRAY, blockedCapacities)
      }
    />
  );
};
