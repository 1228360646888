import React, { ReactNode, useState } from 'react';
import { Grid, InputAdornment, TextField, useMediaQuery, useTheme } from '@mui/material';
import { Search } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { KeyedMutator } from 'swr/_internal';
import useSWR from 'swr';
import { useDebounce } from 'use-debounce';
import { TableComponent } from './TableComponent';

export type CellProps<TEntity> = {
  id: string;
  alignment: 'left' | 'center' | 'right' | 'justify' | 'inherit' | undefined;
  label?: ReactNode;
  render?: (entity: TEntity) => any;
  width?: number;
  hideCell?: boolean;
  noPadding?: boolean;
};

type FetcherFunction<T> = (params: { search?: string; page: number; perPage: number }) => Promise<T[]>;

interface UseTableOptions<T> {
  defaultRowsPerPage?: number;
  fetcher: FetcherFunction<T>;
  opId: string;
  actionsComponent?: ReactNode;
  tableDetails: CellProps<T>[];
  hidePaging?: boolean;
  revalidateOnFocus?: boolean;
  hideSearch?: boolean;
}

interface UseTableResult<T> {
  data: T[];
  mutate: KeyedMutator<T[]>;
  TableComponent: JSX.Element;
  isLoadingData: boolean;
}

export function useTable<T>({
  defaultRowsPerPage = 10,
  opId,
  fetcher,
  tableDetails,
  actionsComponent,
  hidePaging,
  hideSearch,
  revalidateOnFocus = true,
}: UseTableOptions<T>): UseTableResult<T> {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);
  const [searchValue, setSearchValue] = useState<string>('');
  const [debouncedSearchValue] = useDebounce(searchValue, isSmallScreen ? 1500 : 500);

  const { data, mutate, isLoading } = useSWR(
    { search: debouncedSearchValue, page: page + 1, perPage: rowsPerPage, opId },
    fetcher,
    { refreshInterval: 20000, revalidateOnFocus },
  );

  const renderTable = () => (
    <Grid container>
      <Grid container justifyContent="space-between" alignItems="center" mx={1}>
        {!hideSearch && (
          <Grid item>
            <TextField
              value={searchValue}
              variant="outlined"
              className="search-input"
              placeholder={t('search')}
              onChange={(e) => {
                setPage(0);
                setSearchValue(e.target.value);
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        )}
        <Grid item>{actionsComponent}</Grid>
      </Grid>
      <TableComponent
        tableDetails={tableDetails}
        hidePaging={hidePaging}
        page={page}
        rowsPerPage={rowsPerPage}
        isLoading={isLoading}
        setRowsPerPage={setRowsPerPage}
        data={data}
        setPage={setPage}
      />
    </Grid>
  );

  return {
    data: data || [],
    mutate,
    TableComponent: renderTable(),
    isLoadingData: isLoading,
  };
}
