/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum CodeExceptionStatus {
  ENTITY_NOT_FOUND = 'entity_not_found',
  VOUCHER_STATUS_ERROR = 'voucher_status_error',
  VOUCHER_USED = 'voucher_used',
  VOUCHER_TOO_OLD = 'voucher_too_old',
  ORDER_NOT_MATCH_VOUCHER = 'order_not_match_voucher',
  CODE_NOT_FOUND = 'code_not_found',
  DISCOUNT_NOT_ACTIVE = 'discount_not_active',
  DISCOUNT_USAGE_LIMIT_EXCEEDED = 'discount_usage_limit_exceeded',
  RETURNING_CUSTOMER_TOO_LATE = 'returning_customer_too_late',
  DISCOUNT_ORDER_PAGE_NOT_VALID = 'discount_order_page_not_valid',
  ORDER_DATE_BEFORE_REDEMPTION_PERIOD = 'order_date_before_redemption_period',
  ORDER_DATE_AFTER_REDEMPTION_PERIOD = 'order_date_after_redemption_period',
  DISCOUNT_NOT_VALID_FOR_ORDERS = 'discount_not_valid_for_orders',
  DISCOUNT_NOT_VALID_FOR_VOUCHERS = 'discount_not_valid_for_vouchers',
  DISCOUNT_ALREADY_USED = 'discount_already_used',
}
