import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { Alert, Grid, useMediaQuery, useTheme } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { includes, isEmpty } from 'lodash';
import Typography from '@mui/material/Typography';
import { usePostHog } from 'posthog-js/react';

import useSWR from 'swr';
import { useCalendar } from '../../CalendarContext/CalendarContext';
import './EditOrderDialog.scss';
import { EditOrderProvider, useEditOrder } from './EditOrderContext';
import TitledDrawer from '../../../common/ui/TitledDrawer';
import {
  DateAvailabilityOut,
  ExtraPaymentLineData,
  OrderData,
  OrderService,
  TimeSlotResponseOut,
  VoucherOut,
  VoucherService,
} from '../../../api';
import { useLanguage } from '../../../common/GeneralUtils';
import { DATE_FORMAT } from '../../CalendarWrapper';
import { PersonalDetails } from '../../../order_page/OrderPage';
import ConfirmationDialog from '../../../common/ui/ConfimationDialog';
import { useAuth } from '../../../session/InternalAuthProvider';
import { getOrderPages } from '../../../common/AccountUtils';
import { ChooseWorkshopSelect } from './components/chooseWorkshopSelect/ChooseWorkshopSelect';
import { ExperienceDetailPanel } from './components/experienceDetailPanel/ExperienceDetailPanel';
import { DateAndTimePanel } from './components/dateAndTimePanel/DateAndTimePanel';
import { PersonalDetailsPanel } from './components/personalDetailsPanel/PersonalDetailsPanel';
import { AccordionPanelsNames } from './components/Accordion';
import { ManagementPanel } from './components/managementPanel/ManagementPanel';
import { PaymentLinksPanel } from './components/paymentLinksPanel/PaymentLinksPanel';
import { CopyLink } from '../../../common/ui/CopyLink';
import { voucherFetcher } from '../../../vouchers/VouchersTable';

enum EditOrderDialogIntent {
  CreateOrder = 'CreateOrder',
  EditOrder = 'EditOrder',
  RedeemVoucher = 'RedeemVoucher',
  CreateVoucher = 'CreateVoucher',
  EditVoucher = 'EditVoucher',
  CloneOrder = 'CloneOrder',
}

const NINE_AM = 60 * 60 * 9;
const getInitialOrder = (parent: any, voucher?: VoucherOut) => {
  let date = moment().format('YYYY-MM-DD');
  let time = NINE_AM;
  if (parent) {
    date = parent.date;
    time = parent.time;
  }

  return {
    date,
    time,
    firstname: voucher?.firstname || '',
    lastname: voucher?.lastname || '',
    phone: voucher?.phone,
    email: voucher?.email || '',
    custom_details: voucher?.custom_details || undefined,
    voucher_greeting_message: voucher?.voucher_greeting_message || undefined,
    recipient_email: voucher?.recipient_email || undefined,
  };
};

enum DateTimeError {
  MISSING_DATE = 'MISSING_DATE',
  MISSING_TIME = 'MISSING_TIME',
}

function mapError(dateTimeError: DateTimeError, t: any) {
  switch (dateTimeError) {
    case DateTimeError.MISSING_DATE:
      return t('calendar.edit_order.errors.missingDate');
    case DateTimeError.MISSING_TIME:
      return t('calendar.edit_order.errors.missingTime');
    default:
      return t('calendar.edit_order.errors.missingDate');
  }
}

const getButtonText = (editOrderDialogIntent: EditOrderDialogIntent, t: any): string => {
  switch (editOrderDialogIntent) {
    case EditOrderDialogIntent.CreateVoucher:
      return t('vouchers.create');
    case EditOrderDialogIntent.EditVoucher:
    case EditOrderDialogIntent.EditOrder:
      return t('save_changes');
    case EditOrderDialogIntent.RedeemVoucher:
      return t('calendar.edit_order.redeemvoucher');
    case EditOrderDialogIntent.CloneOrder:
      return t('calendar.edit_order.cloneOrder');
    case EditOrderDialogIntent.CreateOrder:
    default:
      return t('create_order');
  }
};
const EditOrderDialogContent = ({
  editOrderDialogIntent,
  expandedPanel,
  setExpandedPanel,
  addOrder,
  updateOrder,
  closeEditOrderDialog,
  editOrderDialogState,
  voucherToRedeem,
  redeemVoucherCallback,
  voucherToEdit,
}: {
  editOrderDialogIntent: EditOrderDialogIntent;
  expandedPanel?: AccordionPanelsNames;
  setExpandedPanel: (panelName?: AccordionPanelsNames) => void;
  addOrder?: (order: OrderData) => Promise<void>;
  updateOrder?: (order: OrderData) => void;
  closeEditOrderDialog: any;
  editOrderDialogState: any;
  voucherToRedeem?: VoucherOut;
  redeemVoucherCallback?: (voucher: VoucherOut) => void;
  voucherToEdit?: VoucherOut;
}) => {
  const posthog = usePostHog();
  const { users } = useCalendar();
  // @ts-ignore
  const { editOrderState, setOrderCustomDetails, setOrderPage, setDate: setDateInState } = useEditOrder();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const isEventOrderPage = editOrderState.orderPage.order_page_type === 'event';
  const { t } = useTranslation();
  const { parentId, parent, onSubmitCallback } = editOrderDialogState;
  let order = editOrderDialogState.order as Partial<OrderData>;
  // for some reason direction in theme is cool in iederpage but not here so hacking it manually

  const language = useLanguage();
  let isEditOrder = true;
  if (!order) {
    order = getInitialOrder(parent, voucherToRedeem || voucherToEdit);
    isEditOrder = false;
  }
  const orderInitialDuration = order.duration || 0;
  const [dateValue, setDateValue] = useState(moment(order.date));
  const [availableTimesForEvent, setAvailableTimesForEvent] = useState<TimeSlotResponseOut[]>([]);
  const [timeSeconds, setTimeSeconds] = useState<number>(order.time!);

  const [privateOrderEndTimeSeconds, setPrivateOrderEndTimeSeconds] = useState<number>(
    order.time && !isEventOrderPage
      ? moment.unix(order.time + orderInitialDuration).unix()
      : editOrderState.orderPage.duration + timeSeconds,
  );

  const duration = privateOrderEndTimeSeconds - timeSeconds;

  const [loading, setLoading] = useState(false);
  const [voucherConfirmationDialogIsOpen, setVoucherConfirmationDialogIsOpen] = useState(false);
  const [paymentLink, setPaymentLink] = useState<string | undefined>(undefined);
  const [convertToVoucherDialogIsOpen, setConvertToVoucherDialogIsOpen] = useState(false);
  const [shouldUnarchive, setShouldUnarchive] = useState(false);
  const personalDetailsFormRef = useRef();
  const [detailsStepErrors] = useState({});
  const [voucher, setVoucher] = useState<VoucherOut | undefined>(undefined);
  const [convertedToVoucher, setConvertedToVoucher] = useState(false);
  const [sendingVoucherEmail, setSendingVoucherEmail] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [isLoadingDates, setIsLoadingDates] = useState(false);

  const [availableDatesForEvents, setAvailableDatesForEvents] = useState<DateAvailabilityOut[]>([]);
  const [selectedUsersIds, setSelectedUsersIds] = useState<string[]>(order.employee_ids || []);
  const [comment, setComment] = useState(order.comment || voucherToEdit?.comment);
  const [createPaymentLink, setCreatePaymentLink] = useState(false);
  const { authState } = useAuth();

  const orderPages = getOrderPages(authState.account);
  const isOneOfVoucherIntents = includes(
    [EditOrderDialogIntent.CreateVoucher, EditOrderDialogIntent.EditVoucher],
    editOrderDialogIntent,
  );
  const link = `${window.location.origin}/order/${voucher?.account_id}/${voucher?.experience_id}/${voucher?.order_page_id}?vouchercode=${voucher?.code}`;
  const { mutate: mutateVouchers } = useSWR(
    { search: undefined, page: 1, perPage: 8, opId: 'getVouchers' },
    voucherFetcher,
  );
  const handlePanelChange = (panel: AccordionPanelsNames) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpandedPanel(isExpanded ? panel : undefined);
  };
  // todo: extend error to something smarter or switch to formik when more error handling required.
  const [dateTimeError, setDateTimeError] = useState<DateTimeError | undefined>(undefined);
  const validateDateTimeError = () => {
    // when creating a voucher we dont want to show an error as date is not needed
    if (isEventOrderPage && editOrderDialogIntent !== EditOrderDialogIntent.CreateVoucher) {
      const selectedDate = availableDatesForEvents.find((x) => x.date === dateValue.format(DATE_FORMAT));
      if (!selectedDate) {
        setDateTimeError(DateTimeError.MISSING_DATE);
        return DateTimeError.MISSING_DATE;
      }
      const selectedTime = selectedDate.times.find((x) => x.seconds === timeSeconds);
      if (!selectedTime) {
        setDateTimeError(DateTimeError.MISSING_TIME);
        return DateTimeError.MISSING_TIME;
      }
    }
    setDateTimeError(undefined);
    return undefined;
  };
  useEffect(() => {
    void (async () => {
      if (isEventOrderPage) {
        setIsLoadingDates(true);
        const availableDates = await OrderService.getAvailableDatesAdmin({
          account_id: editOrderState.account.id,
          order_page_id: editOrderState.orderPage.id,
          order_details: {
            ...editOrderState.details,
            persons: 0,
          },
        });
        setAvailableDatesForEvents(availableDates);
      } else {
        setAvailableDatesForEvents([]);
        setPrivateOrderEndTimeSeconds(+editOrderState.orderPage.duration + timeSeconds);
      }
      setIsLoadingDates(false);
    })();
  }, [editOrderState.orderPage]);
  useEffect(() => {
    setPrivateOrderEndTimeSeconds(+editOrderState.details.duration + timeSeconds);
  }, [editOrderState.details.duration]);

  const getNewParentId = () => {
    const formattedDate = dateValue.format(DATE_FORMAT);

    const timeFound = availableDatesForEvents
      .find((x) => x.date === formattedDate)
      ?.times.find((t) => t.seconds === timeSeconds);

    if (timeFound?.eventId && timeFound?.eventId !== parentId) {
      return timeFound.eventId;
    }
    return parentId;
  };
  const setDate = (date: moment.Moment) => {
    setDateValue(date);
    setDateInState(date);
  };

  // when order page changes to private event preselect todays date
  useEffect(() => {
    if (!isEventOrderPage && !isEditOrder) {
      setDate(moment());
    }
  }, [editOrderState.orderPage]);
  useEffect(() => {
    if (!isEventOrderPage) {
      return;
    }
    // if we are editing we would like to use the original values from order and not overide
    if (!isEditOrder) {
      // if there is parent id then datetime should be already taken from there
      if (!parent) {
        const newInitDate = isEmpty(availableDatesForEvents)
          ? moment()
          : moment(availableDatesForEvents.find((x) => moment(x.date) >= moment())?.date);

        const formattedDate = newInitDate.format(DATE_FORMAT);
        const selectedTime = availableDatesForEvents.find((x) => x.date === formattedDate)?.times[0].seconds || NINE_AM;
        setDate(newInitDate);
        setTimeSeconds(selectedTime);
        order.parent_id = getNewParentId();
      }
    }

    setAvailableTimesForEvent(
      availableDatesForEvents.find((x) => x.date === dateValue.format(DATE_FORMAT))?.times || [],
    );
  }, [availableDatesForEvents]);

  const sendRequest = async (personalDetails: PersonalDetails) => {
    setLoading(true);
    const payload = {
      parent_id: parentId,
      details: { ...editOrderState.details, duration: isEventOrderPage ? editOrderState.orderPage.duration : duration },
      date_and_time_details:
        editOrderState.orderPage.order_page_type !== 'event'
          ? {
              date: dateValue.format('YYYY-MM-DD'),
              time: timeSeconds,
            }
          : undefined,
      personal_details: {
        firstname: personalDetails.firstname,
        lastname: personalDetails.lastname,
        email: personalDetails.email,
        phone: personalDetails.phone,
        custom_personal_details: personalDetails.custom_personal_details,
      },
      order_id: '',
      new_parent_id: '' as string | undefined,
      account_id: editOrderState.account.id,
      experience_id: '',
      order_page_id: editOrderState.orderPage.id,
      language,
      employee_ids: [] as string[],
      comment: '' as string | undefined,
      voucher_id_to_redeem: undefined as string | undefined,
      extra_payment_lines: [] as ExtraPaymentLineData[],
      voucher_greeting_message: personalDetails.voucher_greeting_message,
      recipient_email: personalDetails.recipient_email,
    };
    switch (editOrderDialogIntent) {
      case EditOrderDialogIntent.EditOrder: {
        payload.order_id = order.id!;
        payload.new_parent_id = isEventOrderPage ? getNewParentId() : undefined;
        payload.employee_ids = selectedUsersIds;
        payload.comment = comment;
        payload.extra_payment_lines = editOrderState.extraPaymentsLines;
        posthog.capture('Order Edited', { editor: 'admin', payload });
        const orderResp = await OrderService.editOrder({
          ...payload,
          status: shouldUnarchive ? order.last_status : order.status,
        });
        if (updateOrder) {
          updateOrder(orderResp);
        }
        closeEditOrderDialog();
        break;
      }

      case EditOrderDialogIntent.CreateVoucher: {
        payload.language = language;
        payload.experience_id = editOrderState.experience.id;
        const voucher = await VoucherService.createAdminVoucher({
          ...payload,
          ...payload.personal_details,
          ...editOrderState.details,
          comment,
        });
        setVoucher(voucher);
        setVoucherConfirmationDialogIsOpen(true);
        break;
      }

      case EditOrderDialogIntent.RedeemVoucher:
      case EditOrderDialogIntent.CloneOrder:
      case EditOrderDialogIntent.CreateOrder: {
        payload.language = language;
        payload.experience_id = editOrderState.experience.id;
        payload.comment = comment;
        payload.employee_ids = selectedUsersIds;
        payload.parent_id = isEventOrderPage ? getNewParentId() : null;
        payload.voucher_id_to_redeem = voucherToRedeem ? voucherToRedeem.id : undefined;

        const orderResp = await OrderService.createOrder({ ...payload, create_payment_link: createPaymentLink });
        if (addOrder) {
          await addOrder(orderResp);
        }
        if (redeemVoucherCallback && voucherToRedeem) {
          redeemVoucherCallback(voucherToRedeem);
        }
        if (orderResp.payment_link) {
          const getLinkToPage = `${window.location.origin}/payment-link?linkid=${orderResp.payment_link}`;
          setPaymentLink(getLinkToPage);
        } else {
          closeEditOrderDialog();
        }
        break;
      }
      case EditOrderDialogIntent.EditVoucher: {
        posthog.capture('Voucher Edited', { editor: 'admin', payload });
        await VoucherService.editVoucher({
          voucher_id: voucherToEdit!.id,
          ...payload.personal_details,
          order_page_id: editOrderState.orderPage.id,
          comment,
          voucher_greeting_message: payload.voucher_greeting_message,
          recipient_email: payload.recipient_email,
        });

        closeEditOrderDialog();
        break;
      }

      default:
        throw new Error(`Unhandled intent: ${editOrderDialogIntent}`);
    }

    setLoading(false);
  };

  const saveChanges = async () => {
    if (validateDateTimeError()) {
      setExpandedPanel();
      return;
    }
    const data = personalDetailsFormRef;
    // @ts-ignore
    await data.current.submitForm();
    // @ts-ignore
    const { isValid } = data.current;
    if (isValid) {
      // @ts-ignore
      await sendRequest(data.current.values);
      if (onSubmitCallback) {
        onSubmitCallback();
      }
    } else {
      setExpandedPanel(AccordionPanelsNames.PersonalDetailsPanel);
    }
  };

  const shouldDisableDate = (value: any) => {
    if (!isEventOrderPage) {
      return false;
    }
    if (isEmpty(availableDatesForEvents)) {
      return false;
    }
    const formattedDate = value.format('YYYY-MM-DD');
    return !availableDatesForEvents.find((x) => x.date === formattedDate);
  };

  const onDatePickerChange = (newValue: any) => {
    if (newValue) {
      setDate(newValue);
      setAvailableTimesForEvent(
        availableDatesForEvents.find((x) => x.date === newValue.format(DATE_FORMAT))?.times || [],
      );
    }

    if (isEventOrderPage && isEditOrder) {
      const formattedDate = newValue.format(DATE_FORMAT);
      const selectedTime = availableDatesForEvents.find((x) => x.date === formattedDate)!.times[0].seconds;
      setTimeSeconds(selectedTime);
    }
  };

  return (
    <Grid
      container
      flexDirection="column"
      width={isSmallScreen ? 'auto' : '550px'}
      p={isSmallScreen ? '8px' : '16px'}
      pb="0px"
      overflow="hidden"
      height="100%">
      <Grid
        p="8px"
        container
        flexDirection="column"
        style={{ overflowY: 'scroll', maxHeight: 'calc(100% - 70px)' }}
        flexWrap="nowrap"
        height="100%">
        {editOrderDialogIntent === EditOrderDialogIntent.EditVoucher && (
          <Grid mb={2}>
            <Alert severity="warning">{t('vouchers.editAlert')}</Alert>
          </Grid>
        )}
        <Grid container mb={2} flexDirection="column">
          <ChooseWorkshopSelect
            orderPages={orderPages}
            editOrderState={editOrderState}
            setOrderPage={setOrderPage}
            setExpandedPanel={setExpandedPanel}
          />
        </Grid>
        {!isOneOfVoucherIntents && (
          <DateAndTimePanel
            dateValue={dateValue}
            isEventOrderPage={isEventOrderPage}
            shouldDisableDate={shouldDisableDate}
            onDatePickerChange={onDatePickerChange}
            availableTimesForEvent={availableTimesForEvent}
            timeSeconds={timeSeconds}
            setTimeSeconds={setTimeSeconds}
            privateOrderEndTimeSeconds={privateOrderEndTimeSeconds}
            setPrivateOrderEndTimeSeconds={setPrivateOrderEndTimeSeconds}
            duration={duration}
            expanded={expandedPanel}
            handleChange={handlePanelChange}
          />
        )}
        <ExperienceDetailPanel
          detailsStepErrors={detailsStepErrors}
          isLoadingDates={isLoadingDates}
          expanded={expandedPanel}
          handleChange={handlePanelChange}
        />
        <PersonalDetailsPanel
          orderOrVoucher={order}
          personalDetailsFormRef={personalDetailsFormRef}
          orderPage={editOrderState.orderPage}
          isOneOfVoucherIntents={isOneOfVoucherIntents}
          setOrderCustomDetails={setOrderCustomDetails}
          expanded={expandedPanel}
          handleChange={handlePanelChange}
        />

        <ManagementPanel
          isEventOrderPage={isEventOrderPage}
          users={users}
          selectedUsersIds={selectedUsersIds}
          setSelectedUsersIds={setSelectedUsersIds}
          comment={comment}
          setComment={setComment}
          expanded={expandedPanel}
          handleChange={handlePanelChange}
          orderId={order?.id}
          isOneOfVoucherIntents={isOneOfVoucherIntents}
          shouldUnarchive={shouldUnarchive}
          setShouldUnarchive={setShouldUnarchive}
          orderOrVoucher={order}
        />

        {includes([EditOrderDialogIntent.CreateOrder, EditOrderDialogIntent.CloneOrder], editOrderDialogIntent) && (
          <PaymentLinksPanel
            createPaymentLink={createPaymentLink}
            isEditOrder={isEditOrder}
            setCreatePaymentLink={setCreatePaymentLink}
            expanded={expandedPanel}
            handleChange={handlePanelChange}
          />
        )}
        {dateTimeError ? (
          <Grid mt={2}>
            <Alert severity="error">{mapError(dateTimeError, t)}</Alert>
          </Grid>
        ) : null}
      </Grid>
      <Grid container justifyContent={isEditOrder ? 'space-between' : 'flex-end'}>
        {isEditOrder && (
          <LoadingButton
            disabled={order?.status === 'archived'}
            type="submit"
            color="primary"
            loading={loading}
            variant="outlined"
            onClick={() => setConvertToVoucherDialogIsOpen(true)}
            sx={{ my: 2, textTransform: 'none' }}>
            {t('vouchers.convertDialog.convertButton')}
          </LoadingButton>
        )}
        <LoadingButton
          style={{ textTransform: 'none' }}
          type="submit"
          color="primary"
          loading={loading}
          variant="contained"
          onClick={saveChanges}
          sx={{ my: 2 }}>
          {getButtonText(editOrderDialogIntent, t)}
        </LoadingButton>
        <ConfirmationDialog
          title={t('calendar.edit_order.paymentLink')}
          content={<CopyLink link={paymentLink} />}
          isOpen={!!paymentLink}
          onClose={() => {
            setPaymentLink(undefined);
            closeEditOrderDialog();
          }}
          hideCancelButton
          confirmButtonColor={'primary'}
          ctaText={t('vouchers.confirmation_dialog.cta')}
        />
        <ConfirmationDialog
          title={t('vouchers.confirmation_dialog.title')}
          content={
            <Grid container flexDirection="column">
              <CopyLink link={link} />
              <Grid item hidden={!convertedToVoucher} px={4} mt={4}>
                <LoadingButton
                  disabled={emailSent}
                  fullWidth
                  variant="outlined"
                  loading={sendingVoucherEmail}
                  onClick={async () => {
                    setSendingVoucherEmail(true);
                    await VoucherService.sendVoucher({ voucher_id: voucher!.id });
                    setEmailSent(true);
                    setSendingVoucherEmail(false);
                  }}>
                  {emailSent
                    ? t('vouchers.confirmation_dialog.sent')
                    : t('vouchers.confirmation_dialog.sendToCustomer')}
                </LoadingButton>
              </Grid>
            </Grid>
          }
          isOpen={voucherConfirmationDialogIsOpen}
          onClose={() => {
            setVoucherConfirmationDialogIsOpen(false);
            closeEditOrderDialog();
            if (mutateVouchers && voucher) {
              void mutateVouchers([voucher]);
            }
          }}
          hideCancelButton
          confirmButtonColor={'primary'}
          ctaText={t('vouchers.confirmation_dialog.cta')}
        />
        <ConfirmationDialog
          title={t('vouchers.convertDialog.title')}
          content={<Typography>{t('vouchers.convertDialog.areYouSure')}</Typography>}
          isOpen={convertToVoucherDialogIsOpen}
          onClose={async (actionConfirmed: boolean) => {
            if (actionConfirmed) {
              posthog.capture('Button clicked', {
                name: 'Convert to voucher',
              });
              const voucher = await VoucherService.convertToVoucher({ order_id: order.id! });
              setVoucher(voucher);
              setConvertedToVoucher(true);
              setVoucherConfirmationDialogIsOpen(true);
            }
            setConvertToVoucherDialogIsOpen(false);
          }}
          loadingButton
          confirmButtonColor={'primary'}
          ctaText={t('vouchers.convertDialog.cta')}
        />
      </Grid>
    </Grid>
  );
};

const EditOrderDialog = ({
  addOrder,
  updateOrder,
  editOrderDialogState,
  closeEditOrderDialog,
  redeemVoucherCallback,
}: {
  addOrder?: (order: OrderData) => Promise<void>;
  updateOrder?: (order: OrderData) => void;
  editOrderDialogState: any;
  closeEditOrderDialog: any;
  redeemVoucherCallback?: (voucher: VoucherOut) => void;
}) => {
  // @ts-ignore
  const { t } = useTranslation();
  const { order, orderPageId, voucherToRedeem, createVoucher, voucherToEdit, cloneOrder } = editOrderDialogState;

  const [expandedPanel, setExpandedPanel] = React.useState<AccordionPanelsNames | undefined>(undefined);
  let editOrderDialogIntent = EditOrderDialogIntent.CreateOrder;
  let title = t('calendar.edit_order.create_order');
  if (createVoucher) {
    editOrderDialogIntent = EditOrderDialogIntent.CreateVoucher;
    title = t('calendar.edit_event.create_voucher');
  }

  if (order) {
    editOrderDialogIntent = EditOrderDialogIntent.EditOrder;
    title = `${t('calendar.edit_order.edit_order')} - #${order.order_number}`;
  }
  if (cloneOrder) {
    editOrderDialogIntent = EditOrderDialogIntent.CloneOrder;
    title = `${t('calendar.edit_order.clone_order')}`;
  }
  if (voucherToRedeem) {
    editOrderDialogIntent = EditOrderDialogIntent.RedeemVoucher;
    title = `${t('calendar.edit_order.redeemvoucher')} - #${voucherToRedeem.order_number}`;
  }

  if (voucherToEdit) {
    editOrderDialogIntent = EditOrderDialogIntent.EditVoucher;
    title = `${t('vouchers.edit')} - #${voucherToEdit.order_number}`;
  }

  return (
    <TitledDrawer
      title={title}
      open={editOrderDialogState.isOpen}
      onClose={closeEditOrderDialog}
      openAll={() => setExpandedPanel(AccordionPanelsNames.AllOpen)}>
      <EditOrderProvider order={order} orderPageId={orderPageId} voucherToRedeem={voucherToRedeem}>
        <EditOrderDialogContent
          editOrderDialogState={editOrderDialogState}
          closeEditOrderDialog={closeEditOrderDialog}
          addOrder={addOrder}
          updateOrder={updateOrder}
          editOrderDialogIntent={editOrderDialogIntent}
          expandedPanel={expandedPanel}
          setExpandedPanel={setExpandedPanel}
          voucherToRedeem={voucherToRedeem}
          redeemVoucherCallback={redeemVoucherCallback}
          voucherToEdit={voucherToEdit}
        />
      </EditOrderProvider>
    </TitledDrawer>
  );
};

export default EditOrderDialog;
