import { Chip, FormControl, Grid, MenuItem, Select, Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../orders/Orders.scss';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import { Cancel, Check, Link as MuiLinkIcon } from '@mui/icons-material';
import ConfirmationNumber from '@mui/icons-material/ConfirmationNumber';
import { AccountData, PaymentLinkOut, PaymentLinkPaymentStatus, PaymentLinkService, PaymentLinkType } from '../api';
import { CellProps, useTable } from '../table/useTable';
import { useAuth } from '../session/InternalAuthProvider';
import { getDateFormat } from '../common/getDateAndTimeFormat';
import { getLinkToPaymentLink, getPaymentLinkStatus } from '../calendar/dialogs/EditOrderDialog/OrderDetails';
import CopyToClipboardButton from '../calendar/dialogs/CopyToClipboardButton';
import OrderBoxDialog from '../calendar/calendar-items/OrderBoxDialog';
import EditOrderDialog from '../calendar/dialogs/EditOrderDialog/EditOrderDialog';
import { useCalendar } from '../calendar/CalendarContext/CalendarContext';
import { TABLE_PRICE_ID } from '../table/TableComponent';

const ALL_STATUSES = [PaymentLinkPaymentStatus.PAID, PaymentLinkPaymentStatus.PENDING_PAYMENT];
export const paymentLinkFetcher =
  (statuses?: PaymentLinkPaymentStatus[]) =>
  async ({ search, page, perPage }: { search?: string; page: number; perPage: number }) =>
    PaymentLinkService.getPaymentLinks(page, perPage, search, statuses);

export const PaymentLinkTable = React.memo(() => {
  const { editOrderDrawerState, closeEditOrderDrawer } = useCalendar();
  const { t } = useTranslation();
  const { authState } = useAuth();
  const account = authState.account as unknown as AccountData;
  const { shortDateFormat } = getDateFormat(account);
  const [orderOpen, setOrderOpen] = useState(false);
  const [orderId, setOrderId] = useState<string | undefined>(undefined);

  const [selectedStatuses, setSelectedStatuses] = useState<Array<PaymentLinkPaymentStatus>>(ALL_STATUSES);

  const tableDetails: CellProps<PaymentLinkOut>[] = [
    {
      id: 'title',
      alignment: 'justify',
      label: t('paymentlink.title'),
      render: (paymentLink: PaymentLinkOut) => {
        return (
          <Grid sx={{ maxWidth: 300 }}>
            <Typography sx={{ wordWrap: 'break-word', whiteSpace: 'pre-line' }}>
              {paymentLink.payment_link_type === PaymentLinkType.ADDITIONAL_PAYMENT
                ? paymentLink.title
                : t('paymentlink.paymentForOrder')}
            </Typography>
          </Grid>
        );
      },
    },
    {
      id: TABLE_PRICE_ID,
      alignment: 'center',
      label: t('paymentlink.price'),
    },
    {
      id: 'orderlink',
      alignment: 'center',
      label: t('vouchers.orderlink'),
      render: (paymentLink: PaymentLinkOut) => (
        <Grid
          sx={{ cursor: 'pointer' }}
          item
          onClick={() => {
            setOrderOpen(true);
            setOrderId(paymentLink.order_id);
          }}>
          <Tooltip title={t('info')} arrow>
            <ConfirmationNumber style={{ color: '#bababa' }} />
          </Tooltip>
        </Grid>
      ),
    },
    {
      id: 'status',
      alignment: 'center',
      label: t('paymentlink.statusCol'),
      render: (paymentLink: PaymentLinkOut) => {
        return <Grid>{getPaymentLinkStatus(paymentLink.payment_status, t)}</Grid>;
      },
    },
    {
      id: 'createdAt',
      alignment: 'center',
      label: t('paymentlink.createdAt'),
      render: (paymentLink: PaymentLinkOut) => (
        <Typography>{moment(paymentLink.created_at).format(shortDateFormat)}</Typography>
      ),
    },
    {
      id: 'link',
      alignment: 'center',
      label: t('paymentlink.link'),
      render: (paymentLink: PaymentLinkOut) => (
        <CopyToClipboardButton IconComponent={MuiLinkIcon} text={getLinkToPaymentLink(paymentLink.id)} />
      ),
    },
  ];

  const {
    isLoadingData,
    TableComponent,
    mutate: reFetch,
    data,
  } = useTable<PaymentLinkOut>({
    defaultRowsPerPage: 10,
    opId: 'getPaymentLinks',
    fetcher: paymentLinkFetcher(selectedStatuses),
    tableDetails,
    hideSearch: true,
    actionsComponent: (
      <Grid container my={2}>
        <FormControl fullWidth>
          <Grid container alignItems="center" gap={1}>
            <Typography>{t('paymentlink.statusLabel')}</Typography>
            <Select
              sx={{ minWidth: 300 }}
              size="small"
              multiple
              value={selectedStatuses}
              renderValue={(selected) => (
                <Grid container gap={1}>
                  {selected.map((status) => (
                    <Chip
                      key={status}
                      label={t(`paymentlink.status.${status}`)}
                      onDelete={() => {
                        const remainingStatuses = selected.filter((s) => s !== status);
                        setSelectedStatuses(remainingStatuses.length === 0 ? ALL_STATUSES : remainingStatuses);
                      }}
                      deleteIcon={<Cancel onMouseDown={(event) => event.stopPropagation()} />}
                    />
                  ))}
                </Grid>
              )}
              onChange={(e) =>
                (e.target.value as Array<PaymentLinkPaymentStatus>).length === 0
                  ? setSelectedStatuses(ALL_STATUSES)
                  : setSelectedStatuses(e.target.value as Array<PaymentLinkPaymentStatus>)
              }>
              <MenuItem value={PaymentLinkPaymentStatus.PENDING_PAYMENT}>
                <Grid container alignItems="center">
                  <Grid item xs>
                    {t('paymentlink.status.pending_payment')}
                  </Grid>
                  {selectedStatuses.includes(PaymentLinkPaymentStatus.PENDING_PAYMENT) && (
                    <Grid item>
                      <Check />
                    </Grid>
                  )}
                </Grid>
              </MenuItem>
              <MenuItem value={PaymentLinkPaymentStatus.PAID}>
                <Grid container alignItems="center">
                  <Grid item xs>
                    {t('paymentlink.status.paid')}
                  </Grid>
                  {selectedStatuses.includes(PaymentLinkPaymentStatus.PAID) && (
                    <Grid item>
                      <Check />
                    </Grid>
                  )}
                </Grid>
              </MenuItem>
            </Select>
          </Grid>
        </FormControl>
      </Grid>
    ),
  });
  useEffect(() => {
    void reFetch();
  }, [selectedStatuses, reFetch]);

  return !isLoadingData && data?.length === 0 && selectedStatuses.length !== 0 ? (
    <Typography variant="h6">{t('paymentlink.emptystate')}</Typography>
  ) : (
    <Grid container>
      {TableComponent}
      <OrderBoxDialog open={orderOpen} setOpen={setOrderOpen} orderId={orderId} />
      <EditOrderDialog
        editOrderDialogState={editOrderDrawerState}
        closeEditOrderDialog={() => {
          void closeEditOrderDrawer();
        }}
      />
    </Grid>
  );
});
