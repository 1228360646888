import { FormControl, FormControlLabel, Grid, Radio, RadioGroup } from '@mui/material';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import ConfirmationDialog from '../../../../common/ui/ConfimationDialog';
import { CalendarService, EntityType, UnavailabilityData, UnavailabilityService } from '../../../../api';
import { DeletionMode } from '../../UserAvailabilityDialog/UserAvailabilityDeletionConfirmation';
import { useCalendar } from '../../../CalendarContext/CalendarContext';

type UnvailabilityDeletionConfirmationProps = {
  deleteUnavailability: (unavailability: UnavailabilityData) => void;
  reFetchUnavailabilities: () => void;
};
export const UnvailabilityDeletionConfirmation = ({
  deleteUnavailability,
  reFetchUnavailabilities,
}: UnvailabilityDeletionConfirmationProps) => {
  const { deleteUnavailabilityDialogState, closeDeleteUnavailabilityDialog } = useCalendar();
  const { t } = useTranslation();
  const [deletionOption, setDeletionOption] = useState<DeletionMode>(DeletionMode.SINGLE);
  const [recurrentCount, setRecurrentCount] = useState<number | null>(null);

  const getRecurrentUnavailabilityCount = async (recurrentId: string) => {
    try {
      if (!recurrentId) {
        return;
      }
      const count = await CalendarService.getRecurrentCount(recurrentId, EntityType.UNAVAILABILITY);
      setRecurrentCount(count);
    } catch (error) {
      console.error('Failed to fetch recurrent unavailability count', error);
    }
  };
  useEffect(() => {
    setDeletionOption(DeletionMode.SINGLE);
    void getRecurrentUnavailabilityCount(deleteUnavailabilityDialogState.unavailability?.recurrent_unavailability_id);
  }, [deleteUnavailabilityDialogState.unavailability?.id]);
  const hasRecurrentUnavailability = deleteUnavailabilityDialogState.unavailability?.recurrent_unavailability_id;
  return (
    <ConfirmationDialog
      title={t('calendar.delete_unavailability.title')}
      content={
        hasRecurrentUnavailability ? (
          <Grid container flexDirection="column">
            <Grid item>
              <FormControl component="fieldset">
                <RadioGroup value={deletionOption} onChange={(e) => setDeletionOption(e.target.value as DeletionMode)}>
                  <FormControlLabel
                    value={DeletionMode.SINGLE}
                    control={<Radio />}
                    label={t('orders.unavailability_dialog.single')}
                  />
                  <FormControlLabel
                    value={DeletionMode.FROM_NOW_ON}
                    control={<Radio />}
                    label={
                      <Grid container gap={1} alignItems="baseline">
                        {t('orders.unavailability_dialog.repeating')}
                        {recurrentCount !== null && (
                          <Typography variant="caption" color="grey">
                            {t('calendar.delete_unavailability.recurrent_count', { count: recurrentCount })}
                          </Typography>
                        )}
                      </Grid>
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid>
              <Typography>{t('calendar.delete_unavailability.description')}</Typography>
            </Grid>
          </Grid>
        ) : (
          <Grid>
            <Typography>{t('userAvailability.deleteDialog.final')}</Typography>
          </Grid>
        )
      }
      isOpen={deleteUnavailabilityDialogState?.isOpen}
      onClose={async (actionConfirmed) => {
        closeDeleteUnavailabilityDialog();
        if (actionConfirmed) {
          const unavailabilityToDelete = deleteUnavailabilityDialogState.unavailability;
          if (deletionOption === DeletionMode.SINGLE) {
            await UnavailabilityService.deleteUnavailability(unavailabilityToDelete.id);
            deleteUnavailability(unavailabilityToDelete);
          } else {
            await UnavailabilityService.deleteUnavailabilityRecurrent(
              unavailabilityToDelete.recurrent_unavailability_id,
            );
            reFetchUnavailabilities();
          }
        }
      }}
    />
  );
};
