import React, { PropsWithChildren, useEffect } from 'react';
import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import Color from 'color';
import { useTranslation } from 'react-i18next';
import { ThemeProvider } from '@emotion/react';
import { CustomTheme, DeepPartial } from './types';
import { getResolvedTheme } from './getResolvedTheme';

export const defaultFontFamily = [
  'Roboto',
  'Open Sans Hebrew',
  'sans-serif',
  '-apple-system',
  'BlinkMacSystemFont',
  'Segoe UI',
  'Oxygen',
  'Ubuntu',
  'Cantarell',
  'Fira Sans',
  'Droid Sans',
  'Helvetica Neue',
].toString();
export type PartialCustomTheme = DeepPartial<CustomTheme>;

type SdkThemeProviderProps = {
  remoteThemeOverride?: PartialCustomTheme;
};

// Function to negate colors in a theme
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const negateThemeColors = (theme: CustomTheme) => {
  const negatedTheme = {
    ...theme,
    palette: {
      ...theme.palette,
    },
  };

  // Iterate through each color in the palette and negate it
  for (const key in negatedTheme.palette) {
    // @ts-ignore
    if (typeof negatedTheme.palette[key] === 'string') {
      // @ts-ignore
      negatedTheme.palette[key] = Color(negatedTheme.palette[key]).negate().hex();
    }
  }

  return negatedTheme;
};

const loadFont = (fontName: string) => {
  const fontUrl = `https://fonts.googleapis.com/css2?family=${fontName.replace(
    /\s+/g,
    '+',
  )}:wght@300;400;600;700&display=swap`;

  // Check if the font is already loaded
  if (!document.querySelector(`link[href="${fontUrl}"]`)) {
    const link = document.createElement('link');
    link.href = fontUrl;
    link.rel = 'stylesheet';
    document.head.appendChild(link);
  }
  void document.fonts.load(`16px "${fontName}"`).then(() => {
    console.log(`${fontName} font loaded successfully!`);
  });
};

export const WidgetThemeProvider: React.FC<PropsWithChildren<SdkThemeProviderProps>> = ({
  children,
  remoteThemeOverride,
}) => {
  const { i18n } = useTranslation();
  const theme = getResolvedTheme(remoteThemeOverride);
  useEffect(() => {
    if (remoteThemeOverride?.typography?.fontFamily) {
      loadFont(remoteThemeOverride?.typography?.fontFamily);
    }
  }, []);

  const sliderTheme = {
    MuiSlider: {
      styleOverrides: {
        thumb: {
          backgroundColor: theme.palette.handle,
          '&:hover, &:active': {
            backgroundColor: theme.palette.handle,
            boxShadow: `0px 0px 20px ${theme.palette.handle}`,
            border: `2px solid ${theme.palette.handle}`,
          },
        },
        track: {
          border: `2px solid ${theme.palette.trackFill}`,
          backgroundColor: theme.palette.trackFill,
        },
        rail: {
          backgroundColor: theme.palette.railFill,
        },
      },
    },
  };
  const direction = i18n.dir();
  const createdTheme = createTheme({
    direction,
    typography: {
      fontFamily: remoteThemeOverride?.typography?.fontFamily || defaultFontFamily,
    },
    customTheme: theme,
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          '@global': {
            body: {
              fontFamily: remoteThemeOverride?.typography?.fontFamily || defaultFontFamily,
            },
          },
        },
      },
      ...sliderTheme,
      MuiToggleButton: {
        styleOverrides: {
          root: {
            textTransform: 'none',
          },
        },
      },
      MuiButtonBase: {
        styleOverrides: {
          root: {
            textTransform: 'none',
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: 'none',
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            textTransform: 'none',
          },
        },
      },
    },
  });

  return (
    <MuiThemeProvider theme={createdTheme}>
      <ThemeProvider
        theme={{
          customTheme: theme,
        }}>
        {children}
      </ThemeProvider>
    </MuiThemeProvider>
  );
};
