import * as React from 'react';
import { useState } from 'react';
import { Alert, FormControl, Grid, Grow, MenuItem, Select, useMediaQuery, useTheme } from '@mui/material';
import moment from 'moment';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';

import { Formik } from 'formik';
import { omit } from 'lodash';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import {
  AccountData,
  EntityType,
  OrderPageData,
  OrderPageTypes,
  PrivateSlotsAvailabilityService,
  PrivateSlotType,
  WorkDayType,
} from '../../../../api';
import { useAuth } from '../../../../session/InternalAuthProvider';
import { UseRepeatingDays } from '../../../components/UseRepeatingDays';
import { getOrderPages } from '../../../../common/AccountUtils';
import { FormTextField } from '../../../../experienceSettings/formComponents/FormTextField';
import { FormDatePicker } from '../../../../experienceSettings/formComponents/FormDatePicker';
import { FormSwitch } from '../../../../experienceSettings/formComponents/FormSwitch';
import { FormTimePicker } from '../../../../experienceSettings/formComponents/FormTimePicker';
import { usePrivateSlotsAvailability } from '../../../swr/usePrivateSlotsAvailability';
import { useLanguage } from '../../../../common/GeneralUtils';
import { WorkingHoursFixed } from '../../../../experienceSettings/workingSchedule/WorkingHoursFixed';
import { WorkshopCircle } from '../../../../common/ui/WorkshopCircle';
import { DialogState, RecurrentUpdateDialog } from '../../../components/RecurrentUpdateDialog';
import { useCalendar } from '../../../CalendarContext/CalendarContext';

export const CAPACITIES_BLOCKS_ARRAY = 'capacities_blocks_array';
const DATE_FORMAT = 'YYYY-MM-DD';

export type PrivateSlotAvailabilityFormValues = {
  order_page_id: string;
  description?: string;
  date: string;
  end_date?: string;
  start_time?: number;
  end_time?: number;
  start_seconds?: number[];
  custom_details?: any;
  recurrent_private_slot_id?: string;
  id?: string;
  days?: number[];
  recurrent?: boolean;
  type: PrivateSlotType;
};
export const PrivateSlotAvailabilityDrawer = () => {
  const {
    closeExperienceAvailabilityDrawer,
    experienceAvailabilityDrawerState: { privateSlotAvailability },
    startDate: calendarStartDate,
    endDate: calendarEndDate,
  } = useCalendar();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [updateDialogStatus, setUpdateDialogStatus] = useState(DialogState.CLOSED);
  const { t } = useTranslation();
  const language = useLanguage();

  const { authState } = useAuth();
  const account = authState.account as unknown as AccountData;
  const orderPages = getOrderPages(account);
  // blocking event order pages doesnt make sense as you should just lower the amount of participants.
  const privateOrderPages = orderPages.filter((x: OrderPageData) => x.order_page_type === OrderPageTypes.PRIVATE);
  const hasRecurrentPrivateSlotAvailability = !!privateSlotAvailability?.recurrent_private_slot_id;
  const [isDuplicateError, setIsDuplicateError] = useState(false);
  const [loading, setLoading] = useState(false);
  const defaultStartMoment = moment().hours(9).minutes(0).seconds(0);
  const startInSec = defaultStartMoment.diff(moment().startOf('day'), 'seconds');
  const defaultEndMoment = moment().hours(17).minutes(0).seconds(0);
  const endInSec = defaultEndMoment.diff(moment().startOf('day'), 'seconds');
  const startDate = privateSlotAvailability?.start_time
    ? moment.unix(privateSlotAvailability?.start_time)
    : moment().startOf('day');
  const isUpdatingPrivateSlotAvailability = !!privateSlotAvailability;
  const getInitialCustomDetails = (orderPageData: OrderPageData) => {
    const orderPage = privateOrderPages.filter((o: any) => o.id === orderPageData.id)[0];
    const customDetails = {};
    for (const customDetail of orderPage.custom_details) {
      if (customDetail.cls === 'CustomResourceDetail') {
        // @ts-ignore
        customDetails[customDetail.name] = customDetail.max;
      }
    }
    if (orderPage.availability_settings?.restrictions_obj?.max_parallel_person_capacity?.parallel_persons_capacities) {
      // @ts-ignore
      customDetails[CAPACITIES_BLOCKS_ARRAY] =
        // eslint-disable-next-line max-len
        orderPage.availability_settings?.restrictions_obj?.max_parallel_person_capacity?.parallel_persons_capacities;
    }
    return customDetails;
  };

  const getSlotType = (currentDate: moment.Moment, orderPageData: OrderPageData) => {
    const selectedDayName = currentDate.locale('en').format('dddd');
    // @ts-ignore
    return orderPageData.working_days?.[selectedDayName.toLowerCase()]?.work_day_type;
  };

  const initialValues: PrivateSlotAvailabilityFormValues = isUpdatingPrivateSlotAvailability
    ? {
        order_page_id: privateSlotAvailability.order_page_id,
        start_time: privateSlotAvailability.start_time,
        end_time: privateSlotAvailability.end_time,
        description: privateSlotAvailability.description,
        date: privateSlotAvailability.date,
        start_seconds: privateSlotAvailability?.start_seconds,
        custom_details: privateSlotAvailability.custom_details,
        id: privateSlotAvailability.id,
        recurrent_private_slot_id: privateSlotAvailability.recurrent_private_slot_id,
        days: privateSlotAvailability.days,
        end_date: privateSlotAvailability.end_date,
        recurrent: !!privateSlotAvailability?.recurrent_private_slot_id,
        type: privateSlotAvailability?.type,
      }
    : {
        order_page_id: privateOrderPages[0].id,
        start_time: startInSec,
        end_time: endInSec,
        description: '',
        date: moment().startOf('day').format(DATE_FORMAT),
        start_seconds: [],
        custom_details: getInitialCustomDetails(privateOrderPages[0]),
        days: [0, 1, 2, 3, 4, 5, 6],
        end_date: moment().startOf('day').format(DATE_FORMAT),
        recurrent: false,
        type: getSlotType(moment().startOf('day'), privateOrderPages[0]),
      };
  const validationSchema = {};
  //   yup.object().shape({
  //   description: yup.string().required(t('calendar.privateSlotAvailabilityDialog.description_required')),
  //   all_day: yup.boolean(),
  //   start_date: yup.date().required(t('calendar.privateSlotAvailabilityDialog.date_required')),
  //   start_time: yup.date().when('allDay', {
  //     is: false,
  //     then: yup.date().required(t('calendar.privateSlotAvailabilityDialog.start_time_required')),
  //   }),
  //   end_time: yup.date().when('allDay', {
  //     is: false,
  //     then: yup.date().required(t('calendar.privateSlotAvailabilityDialog.end_time_required')),
  //   }),
  //
  //   availability_details: yup.array().of(
  //     yup.object().shape({
  //       order_page_id: yup.string().required('Order page ID is required'),
  //       custom_details: yup.object(),
  //     }),
  //   ),
  // });
  const {
    addItems: addPrivateSlot,
    updateItem: updatePrivateSlot,
    deleteItem: deletePrivateSlot,
    reFetch: refetchPrivateSlot,
  } = usePrivateSlotsAvailability({
    startDate: calendarStartDate,
    endDate: calendarEndDate,
  });
  const { RepeatingDaysComponent } = UseRepeatingDays({
    minDate: startDate,
    initialDays: initialValues.days,
    initialEndDate: initialValues.end_date,
  });

  const handleClose = () => {
    closeExperienceAvailabilityDrawer();
  };

  const updatePrivateSlotAvliability = async (values: PrivateSlotAvailabilityFormValues) => {
    const updated = await PrivateSlotsAvailabilityService.updatePrivateSlot({
      id: values.id!,
      ...values,
    });
    updatePrivateSlot(updated);
    setLoading(false);
    setUpdateDialogStatus(DialogState.CLOSED);
    handleClose();
  };
  const deletePrivateSlotAvailability = async (values: PrivateSlotAvailabilityFormValues) => {
    const deleted = await PrivateSlotsAvailabilityService.deletePrivateSlot(values.id!);
    deletePrivateSlot(deleted);
    setLoading(false);
    setUpdateDialogStatus(DialogState.CLOSED);
    handleClose();
  };
  const updateRecurrentPrivateSlotAvailability = async (values: PrivateSlotAvailabilityFormValues) => {
    await PrivateSlotsAvailabilityService.updateRecurrentPrivateSlot({
      recurrent_private_slot_id: values.recurrent_private_slot_id!,
      id: values.id!,
      from_date: values.date,
      days: values.days || [],
      end_date: values.end_date!,
      ...values,
    });
    void refetchPrivateSlot();
    setLoading(false);
    setUpdateDialogStatus(DialogState.CLOSED);
    handleClose();
  };
  const deletePrivateRecurrentSlotAvailability = async (values: PrivateSlotAvailabilityFormValues) => {
    const deleted = await PrivateSlotsAvailabilityService.deleteRecurrentPrivateSlot(
      values.recurrent_private_slot_id!,
      values.date,
    );
    deletePrivateSlot(deleted);
    setLoading(false);
    setUpdateDialogStatus(DialogState.CLOSED);
    handleClose();
  };
  const createPrivateSlotAvliability = async (values: PrivateSlotAvailabilityFormValues) => {
    if (values.recurrent) {
      const res = await PrivateSlotsAvailabilityService.createPrivateSlotRecurrent({
        days: values.days!,
        end_date: values.end_date!,
        ...values,
      });
      addPrivateSlot(res);
    } else {
      const res = await PrivateSlotsAvailabilityService.createPrivateSlot(omit(values, ['end_date']));
      addPrivateSlot([res]);
    }
    handleClose();
  };
  const onSubmit = async (values: PrivateSlotAvailabilityFormValues) => {
    setLoading(true);
    setIsDuplicateError(false);
    try {
      if (isUpdatingPrivateSlotAvailability) {
        if (hasRecurrentPrivateSlotAvailability) {
          setUpdateDialogStatus(DialogState.UPDATE);
        } else {
          await updatePrivateSlotAvliability(values);
        }
        return;
      }

      await createPrivateSlotAvliability(values);
    } catch (error: any) {
      if (error?.body.detail) {
        setIsDuplicateError(true);
      }
      console.error('Error submitting form:', error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Grid style={{ height: '100%', overflow: 'auto' }}>
      <Formik enableReinitialize initialValues={initialValues} onSubmit={onSubmit}>
        {({ values, handleSubmit, setFieldValue, touched, isSubmitting, submitForm }) => {
          const selectedDayName = moment(values.date).locale('en').format('dddd');
          const selectedOrderPage = orderPages.find((x: OrderPageData) => x.id === values.order_page_id);
          const dayType = selectedOrderPage?.working_days[selectedDayName.toLowerCase()]?.work_day_type;

          return (
            <form onSubmit={handleSubmit}>
              <Grid
                container
                flexDirection="column"
                width={isSmallScreen ? window.innerWidth : '550px'}
                px={isSmallScreen ? '8px' : '16px'}>
                <Grid p="8px" container flexDirection="column" gap={2}>
                  <Grid container>
                    {!isUpdatingPrivateSlotAvailability && (
                      <Grid mb={3}>
                        <Typography variant={'h6'}>
                          {t('calendar.privateSlotAvailabilityDialog.addAvailability')}
                        </Typography>
                      </Grid>
                    )}

                    <FormControl>
                      <Select
                        style={{ minWidth: '300px' }}
                        onChange={(e) => {
                          const orderPageData = privateOrderPages.find((x: OrderPageData) => x.id === e.target.value);
                          void setFieldValue('order_page_id', e.target.value);
                          void setFieldValue('custom_details', getInitialCustomDetails(orderPageData));
                          void setFieldValue('type', getSlotType(moment(values.date), orderPageData));
                        }}
                        value={values.order_page_id}
                        name={'order_page_id'}
                        renderValue={() => (
                          <Grid container alignItems="center" gap={1}>
                            <WorkshopCircle account={account} orderPageId={values.order_page_id} language={language} />
                            <Grid item>
                              {orderPages.find((x: OrderPageData) => x.id === values.order_page_id).label[language]}
                            </Grid>
                          </Grid>
                        )}>
                        {privateOrderPages.map((x: any) => (
                          <MenuItem key={`privateOrderPages-${x.id}`} value={x.id}>
                            <Grid container alignItems="center" gap={1}>
                              <WorkshopCircle account={account} orderPageId={x.id} language={language} />
                              <Grid item>{x.label[language]}</Grid>
                            </Grid>
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item mt={-2}>
                    <Link to={`/dashboard/settings/${values.order_page_id}#working_days`} style={{ color: 'gray' }}>
                      <Typography variant={'subtitle1'}>
                        {t('calendar.privateSlotAvailabilityDialog.linkToAvailability', {
                          label: selectedOrderPage?.label[language],
                        })}
                      </Typography>
                    </Link>
                  </Grid>
                  <Grid item>
                    <FormTextField
                      width={231}
                      label={t('calendar.privateSlotAvailabilityDialog.description')}
                      fieldName={'description'}
                      isFieldUpdate={isUpdatingPrivateSlotAvailability}
                      disabled={isSubmitting}
                    />
                  </Grid>
                  <Grid>
                    <FormDatePicker
                      disabled={isSubmitting}
                      fieldName={'date'}
                      isFieldUpdate={isUpdatingPrivateSlotAvailability}
                      label={t('calendar.privateSlotAvailabilityDialog.date')}
                      onChange={(newValue) => {
                        if (newValue?.isSameOrAfter(values.end_date)) {
                          void setFieldValue('end_date', newValue?.format(DATE_FORMAT));
                        }
                        void setFieldValue('type', getSlotType(newValue, selectedOrderPage));
                      }}
                    />
                  </Grid>
                  {dayType === WorkDayType.INTERVAL ? (
                    <Grid container flexDirection="column" gap={2}>
                      <Alert severity={'warning'}>
                        <Typography color={'#ed6c02'}>
                          {t('calendar.privateSlotAvailabilityDialog.intervalAlert')}
                        </Typography>
                      </Alert>
                      <Grid container gap={2}>
                        <Grid item>
                          <FormTimePicker
                            minTime={moment().set({ hour: 7, minute: 0, second: 0, millisecond: 0 })}
                            label={t('calendar.privateSlotAvailabilityDialog.start_time')}
                            fieldName={'start_time'}
                            disabled={isSubmitting}
                            isFieldUpdate
                          />
                        </Grid>
                        <Grid item>
                          <FormTimePicker
                            minTime={moment().set({ hour: 7, minute: 0, second: 0, millisecond: 0 })}
                            label={t('calendar.privateSlotAvailabilityDialog.end_time')}
                            fieldName={'end_time'}
                            disabled={isSubmitting}
                            isFieldUpdate
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid item>
                      <WorkingHoursFixed
                        isSubmitting={isSubmitting}
                        initialStartSeconds={initialValues.start_seconds}
                      />
                    </Grid>
                  )}

                  {!isUpdatingPrivateSlotAvailability && (
                    <Grid
                      item
                      borderRadius="8px"
                      border="1px solid #c4c4c4"
                      p={1}
                      maxWidth={!values.recurrent ? '231px' : undefined}>
                      <FormSwitch
                        fieldName={'repeating.enabled'}
                        label={t('calendar.privateSlotAvailabilityDialog.recurrent')}
                        isFieldUpdate={isUpdatingPrivateSlotAvailability}
                        onChange={(e: any) => {
                          void setFieldValue('recurrent', e.target.checked);
                          void setFieldValue('end_date', moment(values.date).startOf('day').format(DATE_FORMAT));
                        }}
                        disabled={isSubmitting}
                      />

                      {values.recurrent && (
                        <Grow in={values.recurrent} timeout={500}>
                          <Grid>
                            <RepeatingDaysComponent
                              setFormikDays={(newDays) => {
                                void setFieldValue('repeating.days', newDays);
                                void setFieldValue('repeating.enabled', true);
                              }}
                              setFormikEndDate={(newEndDate) => setFieldValue('end_date', newEndDate)}
                            />
                          </Grid>
                        </Grow>
                      )}
                    </Grid>
                  )}
                  {/* <Grid> currently Backend doesnt support customer values here so we wont set them  */}
                  {/*  <RenderCustomDetails */}
                  {/*    orderPageId={values.order_page_id} */}
                  {/*    updateCustomDetails={(_, key, value) => { */}
                  {/*      void setFieldValue('custom_details', { ...values.custom_details, [key]: value }); */}
                  {/*    }} */}
                  {/*    customDetails={values.custom_details} */}
                  {/*  /> */}
                  {/* </Grid> */}

                  {isDuplicateError && (
                    <Alert severity="warning">{t('calendar.privateSlotAvailabilityDialog.duplicate')}</Alert>
                  )}
                </Grid>

                <Grid p={2} container justifyContent={isUpdatingPrivateSlotAvailability ? 'space-between' : 'flex-end'}>
                  {isUpdatingPrivateSlotAvailability && (
                    <Button
                      variant="outlined"
                      sx={{ textTransform: 'none' }}
                      onClick={
                        hasRecurrentPrivateSlotAvailability
                          ? () => setUpdateDialogStatus(DialogState.DELETE)
                          : async () => {
                              await deletePrivateSlotAvailability(values);
                            }
                      }
                      color={'error'}>
                      {t('delete')}
                    </Button>
                  )}
                  <LoadingButton
                    onClick={submitForm}
                    loading={loading}
                    variant="contained"
                    sx={{ textTransform: 'none' }}
                    disabled={!touched}>
                    {t('save')}
                  </LoadingButton>
                </Grid>
                {updateDialogStatus !== DialogState.CLOSED && (
                  <RecurrentUpdateDialog
                    dialogState={updateDialogStatus}
                    setDialogStatus={setUpdateDialogStatus}
                    recurrentId={privateSlotAvailability?.recurrent_private_slot_id}
                    recurrentEntityType={EntityType.PRIVATE_SLOT}
                    deleteSingle={() => deletePrivateSlotAvailability(values)}
                    deleteReccurrent={() => deletePrivateRecurrentSlotAvailability(values)}
                    updateSingle={() => updatePrivateSlotAvliability(values)}
                    updateRecurrent={() => updateRecurrentPrivateSlotAvailability(values)}
                    texts={{
                      update: {
                        cta: t('calendar.privateSlotAvailabilityDialog.edit_cta'),
                        title: t('calendar.privateSlotAvailabilityDialog.edit_title_questions'),
                        singleOption: t('calendar.privateSlotAvailabilityDialog.singleUpdate'),
                        recurrentOption: t('calendar.privateSlotAvailabilityDialog.repeatingUpdate'),
                        recurrentCount: t('calendar.privateSlotAvailabilityDialog.recurrent_count'),
                      },
                      delete: {
                        title: t('calendar.privateSlotAvailabilityDialog.deletetitle'),
                        singleOption: t('calendar.privateSlotAvailabilityDialog.single'),
                        recurrentOption: t('calendar.privateSlotAvailabilityDialog.repeating'),
                        recurrentCount: t('calendar.privateSlotAvailabilityDialog.recurrent_count'),
                      },
                    }}
                  />
                )}
              </Grid>
            </form>
          );
        }}
      </Formik>
    </Grid>
  );
};
