import { Box, ClickAwayListener, Fade, Grid, Paper, Stack, Tooltip, Typography } from '@mui/material';
import React from 'react';
import { Close, Delete, Edit, EventBusy, Loop, QuestionMark } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import './UnavailabilityBoxPopper.scss';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { usePostHog } from 'posthog-js/react';
import { isEmpty } from 'lodash';
import { useCalendar } from '../CalendarContext/CalendarContext';
import ResponsivePopper from '../../common/ui/ResponsivePopper';
import { useAuth } from '../../session/InternalAuthProvider';
import { useLanguage } from '../../common/GeneralUtils';
import { AccountData, OrderPageData, UnavailabilityData, UnavailabilityDetailData } from '../../api';
import {
  CAPACITIES_BLOCKS_ARRAY,
  MAX_PARALLEL_PERSON_CAPACITY,
  STATIONS,
} from '../dialogs/PrivateExperianceAvailability/PrivateUnavailability/PrivateUnavailabilityDrawer';
import { UseAuthPermissions } from '../../session/UseAuthPermissions';
import { WorkshopCircle } from '../../common/ui/WorkshopCircle';
import { getOrderPages } from '../../common/AccountUtils';
import { getTimeFormat } from '../../common/getDateAndTimeFormat';

const UnavailabilityBoxPopper = (props: { anchorEl: any; setAnchorEl: any; unavailability: UnavailabilityData }) => {
  const { anchorEl, setAnchorEl, unavailability } = props;

  const { readOnly } = UseAuthPermissions();
  const open = Boolean(anchorEl);
  const { t } = useTranslation();
  const { openDeleteUnavailabilityDialog, openExperienceAvailabilityDrawer } = useCalendar();
  const posthog = usePostHog();
  const { authState } = useAuth();
  const language = useLanguage();
  const account = authState.account as unknown as AccountData;
  const timeFormat = getTimeFormat(account);

  const orderPages = getOrderPages(account, true);
  const element = document.querySelector('#calendar-container');

  return (
    <ResponsivePopper
      open={open}
      anchorEl={anchorEl}
      placement="left-start"
      modifiers={[
        {
          name: 'flip',
          enabled: true,
          options: {
            boundary: element,
            fallbackPlacements: ['left-start', 'left-end', 'right-start', 'right-end', 'top', 'bottom'],
          },
        },
        {
          name: 'preventOverflow',
          options: {
            altAxis: true,
            padding: 10,
          },
        },
      ]}>
      <Fade in timeout={520}>
        <div className="height-100">
          <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
            <Paper elevation={24} className="unavailability-box-popper-content height-100">
              <Stack direction="row" justifyContent="flex-end" padding="2px">
                <Tooltip title={readOnly ? t('noPermissions') : t('delete')} arrow>
                  <span>
                    <IconButton
                      disabled={readOnly}
                      onClick={() => {
                        openDeleteUnavailabilityDialog({ unavailability });
                        setAnchorEl(null);
                      }}>
                      <Delete />
                    </IconButton>
                  </span>
                </Tooltip>
                <Tooltip title={readOnly ? t('noPermissions') : t('edit')} arrow>
                  <span>
                    <IconButton
                      disabled={readOnly}
                      onClick={() => {
                        posthog.capture('Editing unavailability', { unavailability });
                        setAnchorEl(null);
                        openExperienceAvailabilityDrawer({ unavailability });
                      }}>
                      <Edit />
                    </IconButton>
                  </span>
                </Tooltip>

                <IconButton onClick={() => setAnchorEl(null)}>
                  <Tooltip title={t('close')} arrow>
                    <Close />
                  </Tooltip>
                </IconButton>
              </Stack>

              <Box className="details-container">
                <Box className="detail-container">
                  <div className="circle" />
                  {unavailability.recurrent_unavailability_id && (
                    <Tooltip title={t('calendar.unavailability_dialog.recurrent')} placement="top" arrow>
                      <Loop style={{ width: '24px', padding: '2px' }} />
                    </Tooltip>
                  )}
                  <Box>{t('calendar.unavailability')}</Box>
                </Box>

                <Box className="date-container">
                  {unavailability.all_day ? (
                    <Grid container flexDirection="row">
                      <Typography variant="body1">{t('calendar.unavailability_dialog.all_day')}</Typography>
                      <Grid item mx={1}>
                        ⋅
                      </Grid>
                      <Typography variant="body1">{moment(unavailability.date).format('dddd, MMMM, DD')}</Typography>
                    </Grid>
                  ) : (
                    <Grid container flexDirection="row">
                      <Grid item>{moment(unavailability.date).format('dddd, MMMM, DD')}</Grid>
                      <Grid item mx={1}>
                        ⋅
                      </Grid>
                      <Grid item>
                        {moment
                          .unix(unavailability.start_time || 0)
                          .tz('utc')
                          .format(timeFormat)}{' '}
                        -{' '}
                      </Grid>
                      <Grid item>
                        {moment
                          .unix(unavailability.end_time || 0)
                          .tz('utc')
                          .format(timeFormat)}
                      </Grid>
                    </Grid>
                  )}
                </Box>
                {unavailability.description && (
                  <Box className="detail-container">
                    <div className="icon-container">
                      <QuestionMark />
                    </div>
                    <Box>
                      <Typography variant="body1">{unavailability.description}</Typography>
                    </Box>
                  </Box>
                )}

                {unavailability.order_page_id && (
                  <Grid className="detail-container">
                    <div className="icon-container">
                      <EventBusy />
                    </div>
                    <Typography variant="body1">
                      {orderPages.filter((o: any) => o.id === unavailability.order_page_id)[0].label[language]}
                    </Typography>
                  </Grid>
                )}
                {!isEmpty(unavailability.unavailability_details) && (
                  <Grid container justifyContent="center" gap={1} mt={2} alignItems="center">
                    {unavailability.unavailability_details?.map((detailData: UnavailabilityDetailData) => {
                      const orderPage = orderPages.find(
                        (orderPage: OrderPageData) => orderPage.id === detailData.order_page_id,
                      );
                      return (
                        <Grid container gap={2}>
                          <WorkshopCircle withTooltip account={account} orderPageId={orderPage?.id} language={'he'} />
                          <Grid item>
                            <Typography variant="body2"> {orderPage?.label[language]}</Typography>
                          </Grid>
                          {detailData.custom_details && (
                            <Grid item>
                              {Object.entries(detailData.custom_details).map(([key, value]) => (
                                <Typography variant="body2" fontWeight="200">{`${
                                  key === MAX_PARALLEL_PERSON_CAPACITY
                                    ? t('availabilitySettings.blockResources')
                                    : key === STATIONS
                                    ? 'stations'
                                    : key === CAPACITIES_BLOCKS_ARRAY
                                    ? t('availabilitySettings.blockResources')
                                    : key
                                } ${value}`}</Typography>
                              ))}
                            </Grid>
                          )}
                        </Grid>
                      );
                    })}
                  </Grid>
                )}
                {unavailability.custom_details && (
                  <>
                    {Object.keys(unavailability.custom_details).map((key) => {
                      return (
                        <p>
                          <span>{unavailability.custom_details[key]}</span>&nbsp;
                          <span>
                            {key === MAX_PARALLEL_PERSON_CAPACITY ? t('availabilitySettings.blockResources') : key}
                          </span>
                        </p>
                      );
                    })}
                  </>
                )}
              </Box>
            </Paper>
          </ClickAwayListener>
        </div>
      </Fade>
    </ResponsivePopper>
  );
};

export default UnavailabilityBoxPopper;
