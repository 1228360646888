import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, ToggleButton, ToggleButtonGroup, useMediaQuery, useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import TitledDrawer from '../../../common/ui/TitledDrawer';
import { PrivateUnavailabilityDrawer } from './PrivateUnavailability/PrivateUnavailabilityDrawer';
import { PrivateSlotAvailabilityDrawer } from './PrivateAvailability/PrivateAvailabilityForm';
import { useCalendar } from '../../CalendarContext/CalendarContext';
import { EditEventDialogContent } from '../EditEventDialog/EditEventDialog';
import { getOrderPages } from '../../../common/AccountUtils';
import { AccountData, OrderPageData, OrderPageTypes } from '../../../api';
import { useAuth } from '../../../session/InternalAuthProvider';

enum SelectionType {
  PRIVATE_AVAILABILITY = 'privateAvailability',
  EVENT_AVAILABILITY = 'EventAvailability',
  PRIVATE_UNAVAILABILITY = 'privateUnavailability',
}

const getSelectionAndTitle = (
  isUpdatingUnavailability: boolean,
  isUpdatingAvailability: boolean,
  isUpdatingEvent: boolean,
  hasOnlyEventPages: boolean,
  t: (key: string) => string,
) => {
  const selection = isUpdatingUnavailability
    ? SelectionType.PRIVATE_UNAVAILABILITY
    : isUpdatingEvent
    ? SelectionType.EVENT_AVAILABILITY
    : isUpdatingAvailability
    ? SelectionType.PRIVATE_AVAILABILITY
    : hasOnlyEventPages
    ? SelectionType.EVENT_AVAILABILITY
    : SelectionType.PRIVATE_UNAVAILABILITY;

  const drawerTitle = isUpdatingUnavailability
    ? t('calendar.unavailability_dialog.edit_un_title')
    : isUpdatingEvent
    ? t('calendar.edit_event.edit_event')
    : isUpdatingAvailability
    ? t('calendar.unavailability_dialog.edit_title')
    : hasOnlyEventPages
    ? t('calendar.edit_event.create_event')
    : t('calendar.unavailability_dialog.title');

  return { selection, drawerTitle };
};
export const ExperienceAvailabilityDrawerWrapper = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const {
    closeExperienceAvailabilityDrawer,
    experienceAvailabilityDrawerState: { event, unavailability, privateSlotAvailability, isOpen },
  } = useCalendar();
  const { t } = useTranslation();
  const { authState } = useAuth();
  const account = authState.account as unknown as AccountData;
  const hasOnlyEventPages =
    getOrderPages(account).find((x: OrderPageData) => x.order_page_type === OrderPageTypes.PRIVATE) === undefined;
  const isUpdatingUnavailability = !!unavailability;
  const isUpdatingAvailability = !!privateSlotAvailability;
  const isUpdatingEvent = !!event;

  const shouldHideOptions = isUpdatingUnavailability || isUpdatingAvailability || isUpdatingEvent || hasOnlyEventPages;
  const { selection: initialSelection, drawerTitle: initialDraaweTitle } = getSelectionAndTitle(
    isUpdatingUnavailability,
    isUpdatingAvailability,
    isUpdatingEvent,
    hasOnlyEventPages,
    t,
  );

  const [selection, setSelection] = useState<SelectionType>(initialSelection);
  const [drawerTitle, setDrawerTitle] = useState(initialDraaweTitle);
  useEffect(() => {
    const { selection: newSelection, drawerTitle: newTitle } = getSelectionAndTitle(
      isUpdatingUnavailability,
      isUpdatingAvailability,
      isUpdatingEvent,
      hasOnlyEventPages,
      t,
    );
    setSelection(newSelection);
    setDrawerTitle(newTitle);
  }, [isUpdatingUnavailability, isUpdatingAvailability, isUpdatingEvent, hasOnlyEventPages]);

  const handleClose = () => {
    closeExperienceAvailabilityDrawer();
  };

  const handleSelectionChange = (event: React.MouseEvent<HTMLElement>, newSelection: SelectionType | null) => {
    if (newSelection !== null) {
      setSelection(newSelection);
    }
  };

  return (
    <TitledDrawer onClose={handleClose} open={isOpen} title={drawerTitle}>
      <Grid container flexDirection="column" height={'100%'}>
        {!shouldHideOptions && (
          <Grid container p={3} mt={2}>
            <ToggleButtonGroup
              value={selection}
              exclusive
              onChange={handleSelectionChange}
              aria-label="availability selection">
              <ToggleButton value={SelectionType.EVENT_AVAILABILITY} aria-label="availability">
                <Typography variant={isSmallScreen ? undefined : 'h6'}>
                  {t('calendar.edit_event.create_event')}
                </Typography>
              </ToggleButton>
              <ToggleButton value={SelectionType.PRIVATE_AVAILABILITY} aria-label="availability">
                <Typography variant={isSmallScreen ? undefined : 'h6'}>
                  {t('calendar.unavailability_dialog.Buttontitle')}
                </Typography>
              </ToggleButton>
              <ToggleButton value={SelectionType.PRIVATE_UNAVAILABILITY} aria-label="unavailability">
                <Typography variant={isSmallScreen ? undefined : 'h6'}>
                  {t('calendar.privateSlotAvailabilityDialog.unavliability')}
                </Typography>
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
        )}

        <Grid
          style={{ height: `calc(100% - ${shouldHideOptions ? 0 : '200px'})`, overflow: 'auto' }}
          mt={shouldHideOptions ? 3 : undefined}>
          {selection === SelectionType.PRIVATE_UNAVAILABILITY ? (
            <PrivateUnavailabilityDrawer />
          ) : selection === SelectionType.EVENT_AVAILABILITY ? (
            <EditEventDialogContent />
          ) : (
            <PrivateSlotAvailabilityDrawer />
          )}
        </Grid>
      </Grid>
    </TitledDrawer>
  );
};
