import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import {
  DndContext,
  DragEndEvent,
  MouseSensor,
  pointerWithin,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { useTranslation } from 'react-i18next';
import { DraggableItem, ImageItem, LineBreakItem, TextItem } from './DraggableItems';
import { BuildingBlockType } from '../../../api';

function isBuildingBlockType(value: string): value is BuildingBlockType {
  return Object.values(BuildingBlockType).includes(value as BuildingBlockType);
}

interface DragDropContextType {
  items: DraggableItem[];
  updateItem: (itemToUpdate: DraggableItem) => void;
  removeItem: (itemToRemove: DraggableItem) => void;
  hasChanges: boolean;
}

const DragDropContext = createContext<DragDropContextType | undefined>(undefined);

export const useDragDropContext = (): DragDropContextType => {
  const context = useContext(DragDropContext);
  if (!context) {
    throw new Error('useDragDropContext must be used within a DragDropProvider');
  }
  return context;
};

interface DragDropProviderProps {
  children: ReactNode;
  initialItems: DraggableItem[];
}

const DragDropProvider: React.FC<DragDropProviderProps> = ({ children, initialItems }) => {
  const { t } = useTranslation();

  const [items, setItems] = useState<DraggableItem[]>([]);
  const hasChanges =
    items.length !== initialItems.length ||
    !items.every((item, index) => {
      return JSON.stringify(item) === JSON.stringify(initialItems[index]);
    });
  const setItemsInternal = (itemsToSet: DraggableItem[]) => {
    setItems([...itemsToSet]);
  };
  useEffect(() => {
    setItemsInternal(initialItems);
  }, [initialItems]);

  const addItem = (type: BuildingBlockType, itemIdToAddAfter?: string) => {
    const id = `${type}-${Date.now()}`;
    let newItem: DraggableItem;

    switch (type) {
      case BuildingBlockType.TEXT:
        newItem = new TextItem(id, t('emailTemplates.emptyText'));
        break;
      case BuildingBlockType.LINE_BREAK:
        newItem = new LineBreakItem(id);
        break;
      case BuildingBlockType.IMAGE:
        newItem = new ImageItem(id, '');
        break;
      default:
        throw new Error('Unsupported item type');
    }
    if (!itemIdToAddAfter) {
      setItemsInternal([...items, newItem]);
      return;
    }

    const indexToAddAfter = items.findIndex((item) => item.id === itemIdToAddAfter) + 1;
    setItemsInternal([...items.slice(0, indexToAddAfter), newItem, ...items.slice(indexToAddAfter)]);
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (active.id !== over?.id) {
      const overItem: DraggableItem | undefined = items.find((item) => item.id === over?.id);
      if (overItem?.isStatic) {
        return;
      }
      if (isBuildingBlockType(active.id as string)) {
        if (!overItem && over?.id !== 'droppable') {
          return;
        }
        addItem(active.id as BuildingBlockType, overItem?.id);
        return;
      }
      const oldIndex = items.findIndex((item) => item.id === active.id);
      const newIndex = items.findIndex((item) => item.id === over?.id);
      setItemsInternal(arrayMove(items, oldIndex, newIndex));
    }
  };
  const updateItem = (updatedItem: DraggableItem) => {
    let newItem: DraggableItem;
    switch (updatedItem.type) {
      case BuildingBlockType.TEXT:
        newItem = new TextItem(updatedItem.id, (updatedItem as TextItem).content);
        break;
      case BuildingBlockType.IMAGE:
        newItem = new ImageItem(updatedItem.id, (updatedItem as ImageItem).src);
        break;
      default:
        throw new Error('Unsupported item type');
    }
    const oldIndex = items.findIndex((item) => item.id === updatedItem.id);

    items[oldIndex] = newItem;
    setItemsInternal(items);
  };

  const removeItem = (itemToRemove: DraggableItem) => {
    setItemsInternal(items.filter((item) => item.id !== itemToRemove.id));
  };

  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor, {
      // Press delay is required for touch devices to avoid drag-and-drop conflict with scrolling
      activationConstraint: {
        delay: 150,
        tolerance: 5,
      },
    }),
  );

  return (
    <DragDropContext.Provider value={{ items, updateItem, removeItem, hasChanges }}>
      <DndContext collisionDetection={pointerWithin} onDragEnd={handleDragEnd} sensors={sensors}>
        {children}
      </DndContext>
    </DragDropContext.Provider>
  );
};

export default DragDropProvider;
