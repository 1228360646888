import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import React from 'react';
import CopyToClipboardButton from '../calendar/dialogs/CopyToClipboardButton';
import { useAuth } from '../session/InternalAuthProvider';
import { AccountData, Currency } from '../api';
import { whatsAppLinkFormatter } from '../common/whatsAppLinkFormatter';

type PriceCellProps = {
  phone: string;
};
export const PhoneCell = ({ phone }: PriceCellProps): JSX.Element | null => {
  const { authState } = useAuth();
  const account = authState.account as unknown as AccountData;
  const shouldShowWatsapp = account.currency === Currency.ILS;
  let PhoneComponent = <Typography>{phone}</Typography>;
  if (shouldShowWatsapp) {
    PhoneComponent = (
      <a className="link" target="_blank" href={whatsAppLinkFormatter(phone, '')} rel="noreferrer">
        <Typography style={{ textDecoration: 'underline' }}>{phone}</Typography>
      </a>
    );
  }
  return (
    <Grid container gap={1} alignItems={'center'}>
      <CopyToClipboardButton text={phone} width={'18px'} color="grey" />
      {PhoneComponent}
    </Grid>
  );
};
