import * as React from 'react';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { CacheProvider } from '@emotion/react';
import { ThemeProvider, useMediaQuery } from '@mui/material';
import { styled } from '@mui/material/styles';
import { cacheLtr, theme } from '../GeneralUtils';

const StyledLinearProgress = styled(LinearProgress)(() => ({
  background: 'rgba(255,255,255,0.5)',
  [`& .${linearProgressClasses.bar}`]: {
    backgroundColor: '#4c5e9a',
  },
}));

const LinearProgressWithLabel = (props) => {
  const { value, total } = props;
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  let percent = Math.round((value / total) * 100);
  if (percent > 100) {
    percent = 100;
  }

  return (
    <CacheProvider value={cacheLtr}>
      <ThemeProvider theme={{ ...theme, direction: 'ltr' }}>
        <Box
          sx={{
            width: '100%',
            color: 'white',
            marginRight: isSmallScreen ? '2px' : '8px',
            marginTop: '4px',
            marginLeft: isSmallScreen ? '2px' : '8px',
            direction: 'ltr',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
          }}>
          <Box sx={{ width: '100%' }}>
            <StyledLinearProgress variant="determinate" value={percent} />
          </Box>
          <Box sx={{ minWidth: 35 }}>
            <Typography
              variant="body2"
              sx={{
                fontWeight: 'bold',
                fontSize: '0.8rem',
                color: value <= total ? 'white' : '#961a1a',
              }}>
              {value}
              <Box
                sx={{
                  marginRight: '2px',
                  marginLeft: '2px',
                  display: 'inline',
                }}>
                /
              </Box>
              {total}
            </Typography>
          </Box>
        </Box>
      </ThemeProvider>
    </CacheProvider>
  );
};

export default LinearProgressWithLabel;
