import React from 'react';
import { FormControlLabel, FormGroup, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLanguage } from '../../common/GeneralUtils';
import { OrderPageTypography } from '../themedComponents/OrderPageTypography';
import { OrderPageChip } from '../themedComponents/OrderPageChip';
import { OrderPageCheckbox } from '../themedComponents/OrderPageCheckbox';
import { OrderPageData, OrderPageTypes } from '../../api';
import { getOrderPageLabel } from '../../calendar/dialogs/PrivateExperianceAvailability/PrivateUnavailability/UnavailabilityOrderPagesMultiSelect';

type MultiOrderPagePickerProps = {
  orderPages: OrderPageData[];
  initialOrderPageIds: string[];
  selectedOrderPageIds: string[];
  setSelectedOrderPageIds: (orderPageIds: string[]) => void;
};

export const MultiOrderPagePicker = ({
  orderPages,
  initialOrderPageIds,
  selectedOrderPageIds,
  setSelectedOrderPageIds,
}: MultiOrderPagePickerProps) => {
  const language = useLanguage();
  const { t } = useTranslation();
  if (orderPages.length === 0) {
    return null;
  }
  const handleToggle = (orderPageId: string) => {
    const newSelected = selectedOrderPageIds.includes(orderPageId)
      ? selectedOrderPageIds.filter((id: string) => id !== orderPageId)
      : [...selectedOrderPageIds, orderPageId];
    setSelectedOrderPageIds(newSelected);
  };

  if (!initialOrderPageIds || initialOrderPageIds.length <= 1) {
    return null;
  }
  const sortedOrderPageIds = initialOrderPageIds.sort((a, b) => {
    const orderPageA =
      orderPages.find((orderPage) => orderPage.id === a)!.order_page_type === OrderPageTypes.PRIVATE ? 0 : 1;
    const orderPageB =
      orderPages.find((orderPage) => orderPage.id === b)!.order_page_type === OrderPageTypes.PRIVATE ? 0 : 1;
    return orderPageA < orderPageB ? -1 : 1;
  });
  return (
    <Grid container direction="column">
      <Grid item mt={2}>
        <OrderPageTypography variant="h3" bold>
          {t('multipleOrderPage.multiselecttitle')}
        </OrderPageTypography>
      </Grid>
      <FormGroup>
        <Grid container gap={2} mt={2} flexWrap="wrap">
          {sortedOrderPageIds.map((orderPageId: string) => (
            <Grid key={orderPageId} maxWidth="250px">
              <OrderPageChip
                style={{ minWidth: '250px', display: 'flex', justifyContent: 'flex-start' }}
                label={
                  <FormControlLabel
                    control={
                      <OrderPageCheckbox
                        checked={selectedOrderPageIds.includes(orderPageId)}
                        onChange={() => handleToggle(orderPageId)}
                      />
                    }
                    label={
                      <OrderPageTypography>{getOrderPageLabel(language, orderPages, orderPageId)}</OrderPageTypography>
                    }
                  />
                }
              />
            </Grid>
          ))}
        </Grid>
      </FormGroup>
    </Grid>
  );
};
