import { useTranslation } from 'react-i18next';
import React, { useCallback, useEffect, useState } from 'react';
import { Grid, Slider, ThemeProvider, useMediaQuery, useTheme } from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { includes, isEmpty } from 'lodash';
import moment, { Moment } from 'moment/moment';
import { CacheProvider } from '@emotion/react';
import { DateAvailabilityOut, DateAvailabilityOutMultipleOrderPages, OrderPageData, UIObjectTypes } from '../../api';
import { cacheLtr, useLanguage } from '../../common/GeneralUtils';
import MonkeybookWidgetFooter from '../../common/ui/MonkeybookWidgetFooter';
import { parseArrayFromQueryString } from '../../orders/orderPageQSSelectorUtil';
import { getOrderPages } from '../../common/AccountUtils';
import { getAvailableDates } from './utils';
import CustomStaticDatePicker from '../steps/dateAndTimeStep/components/CustomStaticDatePicker';
import { CustomDay } from '../steps/dateAndTimeStep/components/CustomDay';
import { DATE_FORMAT } from '../../calendar/CalendarWrapper';
import { OrderPageToggleButton } from '../themedComponents/OrderPageToggleButton';
import { OrderPageTypography } from '../themedComponents/OrderPageTypography';
import { getOrderPageLabel } from '../../calendar/dialogs/PrivateExperianceAvailability/PrivateUnavailability/UnavailabilityOrderPagesMultiSelect';
import NumberSelector from '../../common/ui/NumberSelector';
import { useAvailableDates } from './useAvailableDates';
import RotatingLoader from '../../common/ui/RotatingLoader';
import { dateFormatter } from '../utils';
import { MultiOrderPagePicker } from './MultiOrderPagePicker';
import { useAccountData } from '../AccountSpecificThemeWrapper';

export const MultipleOrderPage = () => {
  const navigate = useNavigate();

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { i18n } = useTranslation();
  const dir = i18n.dir();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const initialOrderPageIds = parseArrayFromQueryString(queryParams, 'orderpages');

  const { t } = useTranslation();
  const language = useLanguage();
  const { accountId } = useParams();
  const account = useAccountData();
  const orderPages =
    initialOrderPageIds.length > 0
      ? getOrderPages(account).filter((o: OrderPageData) => includes(initialOrderPageIds, o.id))
      : getOrderPages(account);

  const [localDate, setLocalDate] = useState<null | Moment>(null);
  const [sliderLocalValue, setSliderLocalValue] = useState(2);
  const [sliderCommittedValue, setSliderCommittedValue] = useState(2);
  const [selectedOrderPageIds, setSelectedOrderPageIds] = useState<string[]>(initialOrderPageIds);
  const languages = account?.customer_facing_widgets_languages;

  const { availableDates: multipleOrderPagesAvailability, isLoading: isLoadingDates } = useAvailableDates(
    accountId!,
    selectedOrderPageIds,
    sliderCommittedValue,
  );

  useEffect(() => {
    setLocalDate(null);
  }, [isLoadingDates]);
  const iframeHeight = document.body.offsetHeight;
  const iframeWidth = document.body.offsetWidth;

  window.parent.postMessage({ height: iframeHeight, width: iframeWidth }, '*');
  const elementRef = useCallback((node: any) => {
    if (!node) return;
    const resizeObserver = new ResizeObserver(() => {
      const iframeHeight = document.body.offsetHeight;
      window.parent.postMessage({ height: iframeHeight }, '*');
    });
    resizeObserver.observe(node);
  }, []);

  if (!orderPages) {
    return null;
  }
  const initAvailableDatesDict = (availableDates: DateAvailabilityOut[]) => {
    const result = {};
    availableDates.forEach((dateObj) => {
      // @ts-ignore
      result[dateObj.date] = dateObj;
    });
    return result;
  };
  const availableDates = getAvailableDates(multipleOrderPagesAvailability);
  const availableDatesDict = initAvailableDatesDict(availableDates || []);

  const firstAvailableDate = availableDates?.find((x) => !x.isDisabled);
  const referenceDate = moment(firstAvailableDate?.date);
  const onDateChange = (newValue: Moment | null) => {
    setLocalDate(newValue);
  };

  const handleOrderPageClick = (orderPageId: string) => {
    navigate(
      `/order/${accountId}/${account?.experiences[0].id}/${orderPageId}?date=${localDate?.format(DATE_FORMAT)}&time=${
        availableDates.find((x) => x.date === localDate?.format(DATE_FORMAT))?.times[0].seconds
      }`,
    );
  };
  // @ts-ignore
  const sliderText = orderPages[0]?.objects.find((x) => x.type === UIObjectTypes.SLIDER)?.label[language];
  const marks = [
    {
      value: 1,
      label: <OrderPageTypography>{1}</OrderPageTypography>,
    },
    {
      value: 20,
      label: <OrderPageTypography>{20}</OrderPageTypography>,
    },
  ];
  const onSliderCommit = (val: number) => {
    setSliderCommittedValue(val);
  };

  const hasAnyMultipleOrderPagesAvailability = multipleOrderPagesAvailability.some((orderPageAvailability) => {
    const selectedDateAvailability = orderPageAvailability.availability.find(
      (dateAvailability) => dateAvailability.date === localDate?.format(DATE_FORMAT),
    );
    return (
      selectedDateAvailability && !selectedDateAvailability.isDisabled && selectedDateAvailability.times.length > 0
    );
  });
  if (!account) {
    return (
      <Grid container justifyContent="space-around" mt={3}>
        <Grid
          container
          minHeight={336}
          sx={{
            borderRadius: '12px',
            width: '322px',
          }}>
          <RotatingLoader />
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid>
      <Grid
        mb={2}
        container
        display="flex"
        justifyContent="space-around"
        sx={{ '::-webkit-scrollbar': { display: 'none' } }}
        style={{
          height: '100%',
          width: '100%',
        }}>
        <Grid
          ref={elementRef}
          container
          flexDirection="column"
          style={{
            borderRadius: '12px',
            background: theme.customTheme.palette.background,
            height: '100%',
            width: '100%',
            maxWidth: '710px',
          }}
          className="order-page-wrapper">
          <Grid
            px={isSmallScreen ? 2 : 4}
            container
            flexDirection="column"
            style={{
              color: theme.customTheme.palette.text.primary,
              border: `2px solid ${theme.customTheme.palette.border}`,
              borderRadius: '12px',
            }}
            boxShadow={4}>
            <Grid container mt={isSmallScreen ? 0 : 3} mb={1} p={4} pt={0} flexDirection="column" alignContent="center">
              <Grid container justifyContent="center">
                <OrderPageTypography style={{ fontSize: '1.8REM', fontWeight: 400 }}>
                  {account?.label?.[language]}
                </OrderPageTypography>
              </Grid>
              <Grid container flexDirection="column" sx={{ borderRadius: '12px' }} px={1} mt={3}>
                <Grid container justifyContent="space-between">
                  <OrderPageTypography variant="h3" bold>
                    {sliderText}
                  </OrderPageTypography>
                  <Grid>
                    <NumberSelector
                      value={sliderLocalValue}
                      setValue={(val: any) => {
                        setSliderLocalValue(val);
                        onSliderCommit(val);
                      }}
                      min={1}
                      max={20}
                    />
                  </Grid>
                </Grid>

                <Grid item my={isSmallScreen ? 0 : 3} mx={3}>
                  <CacheProvider value={cacheLtr}>
                    <ThemeProvider theme={{ ...theme, direction: 'ltr' }}>
                      <Slider
                        defaultValue={2}
                        value={sliderLocalValue}
                        min={1}
                        max={20}
                        marks={marks}
                        onChange={(_, val) => setSliderLocalValue(val as number)}
                        onChangeCommitted={(_, val) => onSliderCommit(val as number)}
                        aria-label="Default"
                      />
                    </ThemeProvider>
                  </CacheProvider>
                </Grid>
              </Grid>
              <Grid container direction="column" mb={3}>
                <MultiOrderPagePicker
                  orderPages={orderPages}
                  initialOrderPageIds={initialOrderPageIds}
                  selectedOrderPageIds={selectedOrderPageIds}
                  setSelectedOrderPageIds={setSelectedOrderPageIds}
                />
              </Grid>
              <Grid item mt={2}>
                <OrderPageTypography variant="h3" bold>
                  {t('multipleOrderPage.when')}
                </OrderPageTypography>
              </Grid>
              {isEmpty(availableDates) && isLoadingDates ? (
                <Grid container justifyContent="space-around" mt={3}>
                  <Grid
                    container
                    minHeight={336}
                    sx={{
                      backgroundColor: theme.customTheme.palette.background,
                      borderRadius: '12px',
                      width: '322px',
                      border: `1px solid ${theme.customTheme.palette.border}`,
                    }}>
                    <RotatingLoader />
                  </Grid>
                </Grid>
              ) : isEmpty(availableDates) && !isLoadingDates ? (
                <Grid container justifyContent="space-around" mt={3}>
                  <Grid
                    container
                    minHeight={336}
                    justifyContent={'center'}
                    alignItems={'center'}
                    sx={{
                      backgroundColor: theme.customTheme.palette.background,
                      borderRadius: '12px',
                      width: '322px',
                      border: `1px solid ${theme.customTheme.palette.border}`,
                    }}>
                    <OrderPageTypography color="button.outlined" variant="h3">
                      {t('order_page.noDates')}
                    </OrderPageTypography>
                  </Grid>
                </Grid>
              ) : (
                <Grid container justifyContent="center">
                  <CustomStaticDatePicker
                    referenceDate={referenceDate}
                    displayStaticWrapperAs="desktop"
                    openTo="day"
                    disablePast
                    value={referenceDate}
                    minDate={moment()}
                    maxDate={moment().add(1, 'month')}
                    slots={{
                      // @ts-ignore
                      day: CustomDay,
                    }}
                    slotProps={{
                      day: {
                        // @ts-ignore
                        date: localDate,
                        minDate: moment(),
                        availableDatesDict,
                        minDateForOrderBasedOnAcctPref: moment(),
                        troubleshoot: false,
                      },
                    }}
                    onChange={onDateChange}
                    theme={theme}
                    dir={dir}
                    shouldDisableDate={(_) => false}
                  />
                </Grid>
              )}

              <Grid
                container
                flexDirection="row"
                gap={2}
                mt={2}
                alignContent="center"
                flexWrap="wrap"
                justifyContent="center">
                {localDate && (
                  <Grid container>
                    <OrderPageTypography variant="h3" bold>
                      {hasAnyMultipleOrderPagesAvailability
                        ? t('multipleOrderPage.selectedDate', { date: dateFormatter(localDate, account) })
                        : t('multipleOrderPage.noAvailableWorkshops')}
                    </OrderPageTypography>
                  </Grid>
                )}

                {multipleOrderPagesAvailability.map(
                  (orderPageAvailability: DateAvailabilityOutMultipleOrderPages, index: number) => {
                    const selectedDateAvailability = orderPageAvailability.availability.find(
                      (dateAvailability: any) => dateAvailability.date === localDate?.format(DATE_FORMAT),
                    );
                    if (
                      !selectedDateAvailability ||
                      selectedDateAvailability.isDisabled ||
                      selectedDateAvailability.times.length === 0
                    ) {
                      return null;
                    }

                    return (
                      <OrderPageToggleButton
                        value={orderPageAvailability.order_page_id}
                        sx={{
                          minWidth: '250px',
                          border: `2px solid ${theme.customTheme.palette.border}`,
                          borderRadius: '12px',
                        }}
                        key={`${orderPageAvailability}-${index}`}
                        onClick={() => handleOrderPageClick(orderPageAvailability.order_page_id)}>
                        <Grid container gap={1} flexDirection="column">
                          <OrderPageTypography>
                            {getOrderPageLabel(language, orderPages, orderPageAvailability.order_page_id)}
                          </OrderPageTypography>
                          <OrderPageTypography variant="body2">
                            {t('multipleOrderPage.available_timesAmount', {
                              timesNumber:
                                selectedDateAvailability.times.length > 5
                                  ? `5+`
                                  : selectedDateAvailability.times.length,
                            })}
                          </OrderPageTypography>
                        </Grid>
                      </OrderPageToggleButton>
                    );
                  },
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <MonkeybookWidgetFooter languages={languages} accountId={accountId} />
    </Grid>
  );
};
