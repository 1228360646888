import React, { useEffect, useMemo, useState } from 'react';

import { useSearchParams } from 'react-router-dom';
import { Grid } from '@mui/material';
import { OrderService } from '../../api';
import OrderUtils, {
  MULTIPLE_TICKETS_DETAILS_FIELD_NAME,
  MULTIPLE_TICKETS_MIN_PARTICIPANTS_FIELD_NAME,
} from '../OrderUtils';
import TTLLocalStorage from '../../common/TTLLocalStorage';
import { PluginType } from '../PluginUtils';
import { WidgetThemeProvider } from '../theme/Theme';
import { deepOmitNull } from '../utils';
import RotatingLoader from '../../common/ui/RotatingLoader';
import { orderPageInitialState } from './OrderPageState';
import { OrderPageContext } from './useOrderPage';
import { useInitOrderPageState } from './initOrderPageState';
import { useLanguageChange } from '../useLanguageChange';

export function pluginEnabled(orderCustomDetail, pluginName) {
  const item = orderCustomDetail[pluginName] === true;
  return !!item;
}

export const OrderPageProvider = ({ children }) => {
  const [searchParams] = useSearchParams();
  const [orderPageState, setOrderPageState] = useState(orderPageInitialState);
  const shouldUseDefaultTheme = searchParams.get('defaulttheme') === 'true';
  useInitOrderPageState({ orderPageState, setOrderPageState });
  const { isLoading: isLoadingLanguage } = useLanguageChange(orderPageState.account?.default_system_language);

  const setStateInLocalStorage = (newState) => {
    const ttl = OrderUtils.ORDER_PAGE_TTL * 1000;
    const key = `order-page-state-${newState.orderPage.id}`;
    TTLLocalStorage.setItem(key, newState, ttl);
  };

  useEffect(() => {
    void (async () => {
      if (orderPageState.isLoadingDates && orderPageState.account) {
        const response = await OrderService.getAvailableDates({
          account_id: orderPageState.account.id,
          order_page_id: orderPageState.orderPage.id,
          order_details: orderPageState.details,
        });
        setOrderPageState({ ...orderPageState, isLoadingDates: false, availableDates: response });
      }
    })();
  }, [orderPageState.isLoadingDates, orderPageState.account]);

  const setOrderDetailValue = (dataType, value) => {
    const tempDetails = {
      ...orderPageState.details,
      [dataType]: value,
    };
    let shouldUseweekendPlugin = false;
    if (pluginEnabled(orderPageState.details?.custom_details, PluginType.WEEKEND_AND_ISRAELI_HOLIDAYS)) {
      shouldUseweekendPlugin = true;
    }
    const price = OrderUtils.calculatePrice(
      orderPageState.orderPage.price,
      tempDetails,
      orderPageState.dateAndTimeDetails,
      orderPageState.orderPage.plugins,
      shouldUseweekendPlugin,
    );

    const newState = {
      ...orderPageState,
      isLoadingDates: true,
      availableDates: [],
      details: {
        ...tempDetails,
        price,
      },
    };
    setStateInLocalStorage(newState);
    setOrderPageState(newState);
  };

  const setCustomOrderDetailValue = (name, value) => {
    const tempDetails = {
      ...orderPageState.details,
      custom_details: {
        ...orderPageState.details.custom_details,
        [name]: value,
      },
    };

    const price = OrderUtils.calculatePrice(
      orderPageState.orderPage.price,
      tempDetails,
      orderPageState.dateAndTimeDetails,
      orderPageState.orderPage.plugins,
    );

    const newState = {
      ...orderPageState,
      isLoadingDates: true,
      availableDates: [],
      details: {
        ...tempDetails,
        price,
      },
    };
    setStateInLocalStorage(newState);
    setOrderPageState(newState);
  };

  const setMultipleTicketsDetails = (multipleTicketsDetails) => {
    const totalPersons = Object.values(multipleTicketsDetails).reduce((sum, val) => sum + val, 0);
    const tempDetails = {
      ...orderPageState.details,
      persons: totalPersons,
      custom_details: {
        ...orderPageState.details.custom_details,
        [MULTIPLE_TICKETS_DETAILS_FIELD_NAME]: {
          ...orderPageState.details.custom_details.multiple_tickets_details,
          ...multipleTicketsDetails,
        },
        [MULTIPLE_TICKETS_MIN_PARTICIPANTS_FIELD_NAME]: orderPageState.orderPage.objects.find(
          (x) => x.type === 'multipleTickets',
        )?.min_total_participants,
      },
    };
    let shouldUseweekendPlugin = false;
    if (pluginEnabled(orderPageState.details.custom_details, PluginType.WEEKEND_AND_ISRAELI_HOLIDAYS)) {
      shouldUseweekendPlugin = true;
    }
    const price = OrderUtils.calculatePrice(
      orderPageState.orderPage.price,
      tempDetails,
      orderPageState.dateAndTimeDetails,
      orderPageState.orderPage.plugins,
      shouldUseweekendPlugin,
    );

    const newState = {
      ...orderPageState,
      isLoadingDates: true,
      availableDates: [],
      details: {
        ...tempDetails,
        price,
      },
    };
    setStateInLocalStorage(newState);
    setOrderPageState(newState);
  };

  const setOrderCustomDetails = (customDetails) => {
    const tempDetails = {
      ...orderPageState.details,
      custom_details: {
        ...orderPageState.details.custom_details,
        ...customDetails,
      },
    };
    let shouldUseweekendPlugin = false;
    if (pluginEnabled(customDetails, PluginType.WEEKEND_AND_ISRAELI_HOLIDAYS)) {
      shouldUseweekendPlugin = true;
    }
    const price = OrderUtils.calculatePrice(
      orderPageState.orderPage.price,
      tempDetails,
      orderPageState.dateAndTimeDetails,
      orderPageState.orderPage.plugins,
      shouldUseweekendPlugin,
    );

    const newState = {
      ...orderPageState,
      isLoadingDates: true,
      availableDates: [],
      details: {
        ...tempDetails,
        price,
      },
    };
    setStateInLocalStorage(newState);
    setOrderPageState(newState);
  };

  const setPersonalDetails = (personalDetails) => {
    const newState = { ...orderPageState, personalDetails };
    setStateInLocalStorage(newState);
    setOrderPageState(newState);
  };

  const setDate = (date) => {
    const tempDateDetails = {
      ...orderPageState.dateAndTimeDetails,
      date,
    };
    const price = OrderUtils.calculatePrice(
      orderPageState.orderPage.price,
      orderPageState.details,
      tempDateDetails,
      orderPageState.orderPage.plugins,
    );

    const newState = {
      ...orderPageState,
      dateAndTimeDetails: {
        ...orderPageState.dateAndTimeDetails,
        date,
      },
      details: {
        ...orderPageState.details,
        price,
      },
    };
    setStateInLocalStorage(newState);
    setOrderPageState(newState);
  };

  const setTime = (time) => {
    const newState = {
      ...orderPageState,
      dateAndTimeDetails: {
        ...orderPageState.dateAndTimeDetails,
        time,
      },
    };
    setStateInLocalStorage(newState);
    setOrderPageState(newState);
  };

  const setPaymentUrl = (val) => {
    setOrderPageState({
      ...orderPageState,
      paymentUrl: val,
    });
  };
  const setTip = (type, value) => {
    const newState = {
      ...orderPageState,
      tip: { value, type },
    };
    setOrderPageState(newState);
  };

  const setActiveStep = (val) => {
    const newState = {
      ...orderPageState,
      activeStep: val,
    };
    setStateInLocalStorage(newState);
    setOrderPageState(newState);
  };
  const setActiveStepAndOrderPageIntent = (activeStep, orderPageIntent) => {
    const newState = {
      ...orderPageState,
      activeStep,
      orderPageIntent,
    };
    setStateInLocalStorage(newState);
    setOrderPageState(newState);
  };
  const reloadDates = () => {
    setOrderPageState({ ...orderPageState, isLoadingDates: true });
  };
  const ctx = useMemo(
    () => ({
      reloadDates,
      orderPageState,
      setOrderDetailValue,
      setCustomOrderDetailValue,
      setOrderCustomDetails,
      setMultipleTicketsDetails,
      setOrderPageState: (val) => {
        setStateInLocalStorage(val);
        setOrderPageState(val);
      },
      setPersonalDetails,
      setDate,
      setTime,
      setPaymentUrl,
      setActiveStep,
      setActiveStepAndOrderPageIntent,
      setTip,
    }),
    [
      reloadDates,
      orderPageState,
      setOrderDetailValue,
      setCustomOrderDetailValue,
      setOrderCustomDetails,
      setOrderPageState,
      setPersonalDetails,
      setDate,
      setTime,
      setPaymentUrl,
      setActiveStep,
      setActiveStepAndOrderPageIntent,
      setTip,
    ],
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const testingTheme = {
    palette: {
      primary: '#ea9a10',
      text: {
        primary: '#ea9a10',
        button: {
          contained: '#404040',
          outlined: '#e3c998',
        },
      },
      background: '#404040',
      toggleButton: {
        selectedBg: '#d82f72',
      },
      border: '#d82f72',
      warning: '#ed6c02',
      calendarPicker: {
        color: '#404040',
      },
    },
  };
  if (!orderPageState.isInitialized || isLoadingLanguage) {
    return (
      <Grid container minHeight={300}>
        <RotatingLoader />
      </Grid>
    );
  }
  const iframeHeight = document.body.offsetHeight;
  const iframeWidth = document.body.offsetWidth;

  window.parent.postMessage({ height: iframeHeight, width: iframeWidth }, '*');

  const externalTheme = deepOmitNull(orderPageState.account.custom_theme);
  const defaultTheme = {
    palette: {
      primary: '#4abd00',
      trackFill: 'rgba(74,189,0,0.63)',
      railFill: 'rgba(74,189,0,0.63)',
      handle: '#1e6203',
      text: {
        button: {
          outlined: '#4abd00',
        },
      },
      border: '#4abd00',
      button: {
        outlined: '#4abd00',
      },
    },
  };
  return (
    <WidgetThemeProvider remoteThemeOverride={shouldUseDefaultTheme ? defaultTheme : externalTheme}>
      <OrderPageContext.Provider value={ctx}>{children}</OrderPageContext.Provider>
    </WidgetThemeProvider>
  );
};
